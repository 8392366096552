import { ViewportType,BlendModes } from "@cornerstonejs/core/dist/esm/enums";
import { useEffect, useRef } from "react";
// import { setCtTransferFunctionForVolumeActor } from "../../utils/helpers";


export const VolumeViewport = ({
  orientation,
  isActive,
  viewportId,
  renderingEngine,
  volumeId,
  isVolumeLoaded,
  invertColors,
  intesity,
  handleClick
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    // renderingEngine.disableElement(viewportId)
    renderingEngine.enableElement({
      viewportId,
      type: ViewportType.ORTHOGRAPHIC,
      element: elementRef.current,
      defaultOptions: {
        orientation: orientation,
        background: [0, 0, 0],
      },
    });
  }, [orientation, renderingEngine, volumeId, viewportId]);

  useEffect(() => {
    async function renderVolume() {
      const viewport = renderingEngine.getViewport(viewportId);
      await viewport.setVolumes([
        { volumeId },
      ]);
      viewport.render();
    }

    if (isVolumeLoaded) {
      renderVolume();
    }
  }, [viewportId, volumeId, isVolumeLoaded, renderingEngine]);

  useEffect(()=>{
    if(intesity!== undefined){
      
      async function renderVolume() {
        const viewport = renderingEngine.getViewport(viewportId);
        await viewport.setVolumes([
          { 
            volumeId, 
            
            blendMode: BlendModes.MAXIMUM_INTENSITY_BLEND,
            slabThickness: intesity,
           },
        ]);
        viewport.render();
      }
  
      if (isVolumeLoaded) {
        renderVolume();
      }
    }
  },[intesity])


  

  return (
    <div
      className={`w-100 h-100 ${isActive ? "viewport-active" : "viewport"} ${
        invertColors ? "invert-colors" : ""
      }`}
      ref={elementRef}
      onClick={(e) => handleClick(e,viewportId)}
    />
  );
};

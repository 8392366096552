import { Events, ViewportType } from "@cornerstonejs/core/dist/esm/enums";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { getDescription } from "../../core/helper";
import { Enums } from "@cornerstonejs/core";

import { CubeIcon } from "@heroicons/react/24/outline";
import { PatientDescriptions } from "./PatientDescriptions";
import { toWindowLevel } from "@cornerstonejs/core/dist/esm/utilities/windowLevel";

const iconStyle = { width: "18px", height: "18px", color: "#4033F2" };

export const Viewport = ({
  index,
  renderingEngine,
  onClick,
  isActive,
  isDescriptionActive,
  invertColors,
  currentStack,
  activeMpr,
  descriptions,
  setDescriptions,
}) => {
  const elementRef = useRef(null);

  const isMpr = activeMpr.hasOwnProperty(index);

  useEffect(() => {
    if (renderingEngine) {
      renderingEngine.enableElement({
        viewportId: `viewport-${index}`,
        type: ViewportType.STACK,
        element: elementRef.current,
      });
    }
  }, [index, renderingEngine]);

  useEffect(() => {
    const element = elementRef.current;

    element.addEventListener(Events.STACK_NEW_IMAGE, descriptionsImage);
    return () =>
      element.removeEventListener(Events.STACK_NEW_IMAGE, descriptionsImage);
  }, []);

  const handleKeyDown = async (event) => {
    const key = event.code;

    if (renderingEngine) {
      const viewport = renderingEngine.getViewport(`viewport-${index}`);
      const currentImageIdIndex = viewport.getCurrentImageIdIndex();
      console.log({currentImageIdIndex})
      if (key === "ArrowDown") {
        const numImages = viewport.getImageIds().length;
        let newImageIdIndex = currentImageIdIndex + 1;
        newImageIdIndex = Math.min(newImageIdIndex, numImages - 1);
        await viewport.setImageIdIndex(newImageIdIndex);
      } else if (key === "ArrowUp") {
        let newImageIdIndex = currentImageIdIndex - 1;
        newImageIdIndex = Math.max(newImageIdIndex, 0);
        await viewport.setImageIdIndex(newImageIdIndex);
      }
    }
  };

  const descriptionsImage = () => {
    if (renderingEngine) {
      const viewport = renderingEngine.getViewport(`viewport-${index}`);
      const image = viewport.getCornerstoneImage();
      const instanceNumber = viewport.getCurrentImageIdIndex() + 1;

      const properties = viewport.getProperties();

      const window_level = toWindowLevel(
        properties?.voiRange?.lower,
        properties?.voiRange?.upper
      );

      const description = getDescription(
        image,
        descriptions,
        index,
        instanceNumber,
        window_level
      );

      setDescriptions((prev) => ({
        ...prev,
        [index]: description,
      }));
    } else {
      console.log("without rendering");
    }
  };

  return (
    <div
      className="position-relative w-100 h-100"
      onClick={(e) => {
        onClick(e, index);
      }}
    >
      {isDescriptionActive &&
      descriptions[index] &&
      descriptions[index].patientName ? (
        <PatientDescriptions
          descriptions={descriptions[index]}
          isMpr={isMpr}
          invertColors={invertColors}
        />
      ) : null}
      <div
        className={`w-100 h-100 ${isActive ? "viewport-active" : "viewport"} ${
          invertColors ? "invert-colors" : ""
        }`}
        ref={elementRef}
        onKeyDown={(e) => handleKeyDown(e)}
      />
    </div>
  );
};

import {
  Types,
  Enums,
  volumeLoader,
  setVolumesForViewports,
  addVolumesToViewports,
  utilities,
  cache,
} from '@cornerstonejs/core';
import * as cornerstone from "@cornerstonejs/core"
import {
  setCtTransferFunctionForVolumeActor,
} from './index'
  import * as StreamingImageVolume from '@cornerstonejs/streaming-image-volume-loader';
import {
  Types as cstTypes
} from '@cornerstonejs/tools';
import { LoadingSpinner } from "../../views/components/LoadingSpinner";

const {
  ViewportType
} = Enums;
const VOLUME_LOADER_SCHEME = 'wadouri';


async function convertVolumeToStackViewport(
  renderingEngine,
  viewport,
  toolGroup,
  viewId,
  stack
) {
  const {
    id,
    element
  } = viewport;




  renderingEngine.disableElement(viewId)

  renderingEngine.enableElement({
    viewportId: viewId,
    type: ViewportType.STACK,
    element,
    defaultOptions: {
      background: [0, 0, 0],
    }
  })



  // Set the tool group on the viewport
  toolGroup.addViewport(id, renderingEngine.id);
 
  const stackViewport = renderingEngine.getViewport(viewId);
  if (stackViewport) {
    // await cache.purgeVolumeCache()
    await stackViewport.setStack([...stack],0);
    viewport.render();
  }

}


async function convertStackToVolumeViewport(
  renderingEngine,
  viewport,
  toolGroup,
  viewId,
  type,
  stack,
  serie
  
) {
 
  const volumeId = `MPR_VOLUME_${serie}` 

  const {
    id,
    element
  } = viewport;
  
  var position = id.split('-')[1]
  var div = document.getElementsByClassName('viewport-grid')[0].childNodes[position]
  var loader = document.createElement('div')
  loader.setAttribute("id", id);
  
    loader.style.color = 'white';
    loader.style.position = 'absolute';
    loader.style.zIndex = '1';
    loader.style.top = '50%';
    loader.style.left = '50%';
    loader.style.transform = 'translate(-50%, -50%)';
    loader.style.fontWeight = 'bold';
    loader.style.fontSize = '40px';
    loader.style.textAlign = 'center'
    loader.innerHTML += 'Renderizando...';
    div.prepend(loader)

  renderingEngine.disableElement(viewId)
  
  renderingEngine.enableElement({
    viewportId: viewId,
    type: ViewportType.ORTHOGRAPHIC,
    element,
    defaultOptions: {
      orientation: Enums.OrientationAxis[type],
      background: [0, 0, 0],
    },
  })
  
  toolGroup.addViewport(id, renderingEngine.id);
  
  
  const volumeInCahe = await cache.getVolume(volumeId) 

  

  const volume = (volumeInCahe != undefined) ? volumeInCahe :  await volumeLoader.createAndCacheVolume(volumeId, {imageIds:[...stack]})   

  // console.log(volume)
  // console.log(viewId)
  // console.log(id.split('-'))
  
  volume.load();
  const volumeViewport = renderingEngine.getViewport(id);
  
  await setVolumesForViewports(
    renderingEngine,[{volumeId}],
    [viewId]
    );
    
    volumeViewport.render();
    loader.remove()
    // console.log("rendering end")
}
export {
  convertStackToVolumeViewport,
  convertVolumeToStackViewport
};
export const LoadingSpinner = () => {
  return (
    <div
      className="position-absolute top-0 start-0 bottom-0 end-0 bg-dark text-white bg-opacity-60 h-100"
      style={{ paddingTop: "240px" }}
    >
      <div className="text-center">
        <div className="spin4" id="loader"></div>
        <div className="spin4" id="loader2"></div>
        <div className="spin4" id="loader3"></div>
        <div className="spin4" id="loader4"></div>
        <div id="text">PROCESANDO </div>
        <div id="text">IMAGENES... </div>
      </div>
    </div>
  );
};

import { CubeIcon } from "@heroicons/react/24/outline";
const iconStyle = { width: "18px", height: "18px", color: "#4033F2" };

export const PatientDescriptions = ({descriptions, isMpr, invertColors}) => {

  return (
    <>
      <div className="position-absolute start-0 top-0 z-1 p-2">
        {isMpr ? (
          <CubeIcon style={iconStyle} />
        ) : (
          <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>Im: {descriptions.instanceNumber}</div>
        )}
      </div>
      <div className="position-absolute end-0 top-0 z-1 p-2 text-end">
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.patientName}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.accession_number}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.gender}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.hospital}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.numberUnk}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.unk}</div>
      </div>

      <div className="position-absolute start-0 bottom-0 z-1 p-2">
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.station}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{descriptions.brand}</div>
        <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"}`}>{`WW/WC:${descriptions.windowingValue.ww}/${descriptions.windowingValue.wc}`}</div>
      </div>
      <div className={`tag ${invertColors ? "text-dark" : "text-white text-opacity-75"} position-absolute end-0 bottom-0 z-1 p-2`}>
        {descriptions.date}
      </div>
    </>
  );
};

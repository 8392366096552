import { Thumbnail } from "./Thumbnail";
import { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";

export const SidebarThumbnail = ({
  serie,
  stack,
  study,
  engine,
  onClick,
  isClicked,
  setMgViewports,
}) => {
  const [thumbName, setThumbName] = useState("");
  return (
    <div className="mb-lg-3">
      <Thumbnail
        serie={serie}
        stack={stack}
        study={study}
        engine={engine}
        setThumbName={setThumbName}
        onClick={onClick}
        isClicked={isClicked}
        setMgViewports={setMgViewports}
      />
      <div className="d-flex justify-content-between text-white thumbTitle">
        {/* <p>
          <span style={{ color: isClicked ? "#4033F2" : "#909294" }}><strong>S:</strong></span>
          {serie}
        </p> */}
        <div>{thumbName.length > 15 ? "Info de estudio" : thumbName}</div>
        <div className="d-flex gap-1 align-items-center">
          <PhotoIcon
            style={{
              width: "16px",
              height: "16px",
              color: isClicked ? "#4033F2" : "#909294",
            }}
          />
          <span>{stack?.length}</span>
        </div>
      </div>
    </div>
  );
};

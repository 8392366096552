import { enviroment } from "../config/enviroment";

export const getimages = soap =>{
    return fetch(`${enviroment.api}${soap}`)
            .then((response) => response.json())
            .then((res) => { return res
                // downloadAndView(res)
            });
}

// export async function getimages(soap) {
//   const response = await fetch(`${enviroment.api}${soap}`);
//   if (!response?.body) return;
 
//   const contentLength = response.headers.get('Content-Length')
//   const totalLength = Number(contentLength)
//   console.log({totalLength})
//   const reader = response.body.getReader();
//   const chunks = [];
//   let receivedLength = 0;

//   while (true) {
//     const { done, value } = await reader.read();
//     if (done) {
//       console.log("done");
//       break;
//     }
//     chunks.push(value);
//     receivedLength = receivedLength + value.length;
//     const step = receivedLength / totalLength  * 100;
//     console.log({step})

//   }
//   const blob = new Blob(chunks);

//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;

//     // function handleOnDownload() {
//     //   setTimeout(() => {
//     //     URL.revokeObjectURL(url);
//     //     a.removeEventListener("click", handleOnDownload);
//     //   }, 150);
//     // }
//     // a.addEventListener("click", handleOnDownload, false);
//     // a.click();
// }

import React from 'react'



export const NotFound = () => {
    
  return (
    <div >
        <img className="img-fluid"   src={require('../assets/images/004.jpg')} alt="Not FOund" />
    </div>
  )
}


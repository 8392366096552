import { toLowHighRange } from "@cornerstonejs/core/dist/esm/utilities/windowLevel";

export const WindowToolbarButton = ({
  iconStyle,
  activeViewport,
  renderingEngine,
  setDescriptions,
  activeMprViewport,
  isActiveMPR
}) => {
  const options = [
    { name: "CT Lungs", wc: -400, ww: 1500 },
    { name: "CT Abdomen", wc: 60, ww: 400 },
    { name: "CT Angio", wc: 300, ww: 600 },
    { name: "CT Bone", wc: 300, ww: 1500 },
    { name: "CT Brain", wc: 40, ww: 80 },
    { name: "CT Chest", wc: 40, ww: 400 },
  ];

  const handleClick = ({ wc, ww }) => {
    
    console.log(isActiveMPR)
    const viewport = isActiveMPR ? renderingEngine.getViewport(`${activeMprViewport}`) : renderingEngine.getViewport(`viewport-${activeViewport}`);
    
    // console.log(renderingEngine.getViewport(`${activeMprViewport}`))

    if (viewport) {
      viewport.setProperties({ voiRange: toLowHighRange(ww, wc) });
      const windowingValue = { wc, ww };
      setDescriptions((prev) => ({
        ...prev,
        [activeViewport]: { ...prev[activeViewport], windowingValue },
      }));
      viewport.render();

    }
  };
  return (
    <div className="btn-group d-none d-lg-block">
      <button
        className="btn btn-dark dropdown-toggle h-100 d-flex flex-column align-items-center py-2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ fontSize: "11px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={iconStyle}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
        <div className="pt-1">Ventanas</div>
      </button>
      <ul
        className="dropdown-menu dropdown-menu-dark w-auto text-center position-fixed"
        style={{ fontSize: "12px" }}
      >
        {options.map((element) => {
          return (
            <li
              key={element.name}
              className="dropdown-item"
              onClick={() => handleClick(element)}
            >
              {element.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

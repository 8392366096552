export const getTags = async metadata => {
    console.log(metadata.elements.x0020000e.tag)
    console.log(metadata.string('x0020000e'))
    let TAGS = [
        { tag_id : '(0002,0000)' ,description : 'File Meta Information Group Length' , vr : (metadata.elements.x00020000) ? metadata.elements.x00020000.vr : '', length : (metadata.elements.x00020000) ? metadata.elements.x00020000.length : '', value : (metadata.elements.x00020000) ? metadata.string(metadata.elements.x00020000.tag) : ''},
        { tag_id : '(0002,0001)' ,description : 'File Meta Information Version' , vr : (metadata.elements.x00020001) ? metadata.elements.x00020001.vr : '', length : (metadata.elements.x00020001) ? metadata.elements.x00020001.length : '', value : (metadata.elements.x00020001) ? metadata.string(metadata.elements.x00020001.tag) :''},
        { tag_id : '(0002,0002)' ,description : 'Media Storage SOP Class UID' , vr : (metadata.elements.x00020002) ? metadata.elements.x00020002.vr : '', length : (metadata.elements.x00020002) ? metadata.elements.x00020002.length : '', value : (metadata.elements.x00020002) ? metadata.string(metadata.elements.x00020002.tag) : ''},
        { tag_id : '(0002,0003)' ,description : 'Media Storage SOP Instance UID' , vr : (metadata.elements.x00020003) ? metadata.elements.x00020003.vr : '', length : (metadata.elements.x00020003) ?  metadata.elements.x00020003.length : '', value :(metadata.elements.x00020003) ? metadata.string(metadata.elements.x00020003.tag):'' },
        { tag_id : '(0002,0010)' ,description : 'Transfer Syntax UID' , vr : (metadata.elements.x00020010) ? metadata.elements.x00020010.vr : '', length : (metadata.elements.x00020010) ? metadata.elements.x00020010.length : '', value : (metadata.elements.x00020010) ? metadata.string(metadata.elements.x00020010.tag) :''},
        { tag_id : '(0002,0012)' ,description : 'Implementation Class UID' , vr :  (metadata.elements.x00020012) ? metadata.elements.x00020012.vr :'', length : (metadata.elements.x00020012) ? metadata.elements.x00020012.length : '', value : (metadata.elements.x00020012) ? metadata.string(metadata.elements.x00020012.tag) :''},
        { tag_id : '(0002,0013)' ,description : 'Implementation Version Name' , vr : (metadata.elements.x00020013) ? metadata.elements.x00020013.vr : ' ', length : (metadata.elements.x00020013) ? metadata.elements.x00020013.length :'', value : (metadata.elements.x00020013) ? metadata.string(metadata.elements.x00020013.tag) :''},
        { tag_id : '(0002,0016)' ,description : 'Source Application Entity Title' , vr : (metadata.elements.x00020016) ? metadata.elements.x00020016.vr : '', length : (metadata.elements.x00020016) ? metadata.elements.x00020016.length : '', value : (metadata.elements.x00020016) ? metadata.string(metadata.elements.x00020016.tag) : '' },
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ? metadata.elements.x00080000.vr :' ', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) : ''},
        { tag_id : '(0008,0005)' ,description : 'Specific Character Set' , vr : (metadata.elements.x00080005) ? metadata.elements.x00080005.vr: '', length : (metadata.elements.x00080005)? metadata.elements.x00080005.length :'', value : (metadata.elements.x00080005) ? metadata.string(metadata.elements.x00080005.tag) :''},
        { tag_id : '(0008,0008)' ,description : 'Image Type' , vr : (metadata.elements.x00080008) ? metadata.elements.x00080008.vr : '', length : (metadata.elements.x00080008) ? metadata.elements.x00080008.length : '', value : (metadata.elements.x00080008)? metadata.string(metadata.elements.x00080008.tag) : ''},
        { tag_id : '(0008,0016)' ,description : 'SOP Class UID' , vr : (metadata.elements.x00080016) ? metadata.elements.x00080016.vr : '', length : (metadata.elements.x00080016) ? metadata.elements.x00080016.length :'', value : (metadata.elements.x00080016) ? metadata.string(metadata.elements.x00080016.tag) :''},
        { tag_id : '(0008,0018)' ,description : 'SOP Instance UID' , vr :  (metadata.elements.x00080018) ? metadata.elements.x00080018.vr : '', length : (metadata.elements.x00080018)?metadata.elements.x00080018.length:'', value : (metadata.elements.x00080018)?metadata.string(metadata.elements.x00080018.tag) :''},
        { tag_id : '(0008,0020)' ,description : 'Study Date' , vr : (metadata.elements.x00080020) ? metadata.elements.x00080020.vr :'', length : (metadata.elements.x00080020) ? metadata.elements.x00080020.length : '', value :(metadata.elements.x00080020) ? metadata.string(metadata.elements.x00080020.tag) :'' },
        { tag_id : '(0008,0021)' ,description : 'Series Date' , vr : (metadata.elements.x00080021) ?  metadata.elements.x00080021.vr :'', length : (metadata.elements.x00080021) ? metadata.elements.x00080021.length :'', value : (metadata.elements.x00080021) ? metadata.string(metadata.elements.x00080021.tag) :''},
        { tag_id : '(0008,0022)' ,description : 'Acquisition Date' , vr : (metadata.elements.x00080022) ?  metadata.elements.x00080022.vr :'', length : (metadata.elements.x00080022) ? metadata.elements.x00080022.length :'', value : (metadata.elements.x00080022) ? metadata.string(metadata.elements.x00080022.tag) :''},
        { tag_id : '(0008,0023)' ,description : 'Content Date' , vr : (metadata.elements.x00080023) ?  metadata.elements.x00080023.vr :'', length : (metadata.elements.x00080023) ? metadata.elements.x00080023.length :'', value : (metadata.elements.x00080023) ? metadata.string(metadata.elements.x00080023.tag) :''},
        { tag_id : '(0008,0030)' ,description : 'Study Time' , vr : (metadata.elements.x00080030) ?  metadata.elements.x00080030.vr :'', length : (metadata.elements.x00080030) ? metadata.elements.x00080030.length :'', value : (metadata.elements.x00080030) ? metadata.string(metadata.elements.x00080030.tag) :''},
        { tag_id : '(0008,0031)' ,description : 'Series Time' , vr : (metadata.elements.x00080031) ?  metadata.elements.x00080031.vr :'', length : (metadata.elements.x00080031) ? metadata.elements.x00080031.length :'', value : (metadata.elements.x00080031) ? metadata.string(metadata.elements.x00080031.tag) :''},
        { tag_id : '(0008,0032)' ,description : 'Acquisition Time' , vr : (metadata.elements.x00080032) ?  metadata.elements.x00080032.vr :'', length : (metadata.elements.x00080032) ? metadata.elements.x00080032.length :'', value : (metadata.elements.x00080032) ? metadata.string(metadata.elements.x00080032.tag) :''},
        { tag_id : '(0008,0033)' ,description : 'Content Time' , vr : (metadata.elements.x00080033) ?  metadata.elements.x00080033.vr :'', length : (metadata.elements.x00080033) ? metadata.elements.x00080033.length :'', value : (metadata.elements.x00080033) ? metadata.string(metadata.elements.x00080033.tag) :''},
        { tag_id : '(0008,0050)' ,description : 'Accession Number' , vr : (metadata.elements.x00080050) ?  metadata.elements.x00080050.vr :'', length : (metadata.elements.x00080050) ? metadata.elements.x00080050.length :'', value : (metadata.elements.x00080050) ? metadata.string(metadata.elements.x00080050.tag) :''},
        { tag_id : '(0008,0060)' ,description : 'Modality' , vr : (metadata.elements.x00080060) ?  metadata.elements.x00080060.vr :'', length : (metadata.elements.x00080060) ? metadata.elements.x00080060.length :'', value : (metadata.elements.x00080060) ? metadata.string(metadata.elements.x00080060.tag) :''},
        { tag_id : '(0008,0068)' ,description : 'Presentation Intent Type' , vr : (metadata.elements.x00080068) ?  metadata.elements.x00080068.vr :'', length : (metadata.elements.x00080068) ? metadata.elements.x00080068.length :'', value : (metadata.elements.x00080068) ? metadata.string(metadata.elements.x00080068.tag) :''},
        { tag_id : '(0008,0070)' ,description : 'Manufacturer' , vr : (metadata.elements.x00080070) ?  metadata.elements.x00080070.vr :'', length : (metadata.elements.x00080070) ? metadata.elements.x00080070.length :'', value : (metadata.elements.x00080070) ? metadata.string(metadata.elements.x00080070.tag) :''},
        { tag_id : '(0008,0080)' ,description : 'Institution Name' , vr : (metadata.elements.x00080080) ?  metadata.elements.x00080080.vr :'', length : (metadata.elements.x00080080) ? metadata.elements.x00080080.length :'', value : (metadata.elements.x00080080) ? metadata.string(metadata.elements.x00080080.tag) :''},
        { tag_id : '(0008,0081)' ,description : 'Institution Address' , vr : (metadata.elements.x00080081) ?  metadata.elements.x00080081.vr :'', length : (metadata.elements.x00080081) ? metadata.elements.x00080081.length :'', value : (metadata.elements.x00080081) ? metadata.string(metadata.elements.x00080081.tag) :''},
        { tag_id : '(0008,0090)' ,description : 'Referring Physician´s Name' , vr : (metadata.elements.x00080090) ?  metadata.elements.x00080090.vr :'', length : (metadata.elements.x00080090) ? metadata.elements.x00080090.length :'', value : (metadata.elements.x00080090) ? metadata.string(metadata.elements.x00080090.tag) :''},
        { tag_id : '(0008,1010)' ,description : 'Station Name' , vr : (metadata.elements.x00081010) ?  metadata.elements.x00081010.vr :'', length : (metadata.elements.x00081010) ? metadata.elements.x00081010.length :'', value : (metadata.elements.x00081010) ? metadata.string(metadata.elements.x00081010.tag) :''},
        { tag_id : '(0008,1030)' ,description : 'Study Description' , vr : (metadata.elements.x00081030) ?  metadata.elements.x00081030.vr :'', length : (metadata.elements.x00081030) ? metadata.elements.x00081030.length :'', value : (metadata.elements.x00081030) ? metadata.string(metadata.elements.x00081030.tag) :''},
        { tag_id : '(0008,1048)' ,description : 'Physician(s) of Record' , vr : (metadata.elements.x00081048) ?  metadata.elements.x00081048.vr :'', length : (metadata.elements.x00081048) ? metadata.elements.x00081048.length :'', value : (metadata.elements.x00081048) ? metadata.string(metadata.elements.x00081048.tag) :''},
        { tag_id : '(0008,1090)' ,description : 'Manufacturer´s Model Name' , vr : (metadata.elements.x00081090) ?  metadata.elements.x00081090.vr :'', length : (metadata.elements.x00081090) ? metadata.elements.x00081090.length :'', value : (metadata.elements.x00081090) ? metadata.string(metadata.elements.x00081090.tag) :''},
        { tag_id : '(0008,1110)' ,description : 'Referenced Study Sequence' , vr : (metadata.elements.x00081110) ?  metadata.elements.x00081110.vr :'', length : (metadata.elements.x00081110) ? metadata.elements.x00081110.length :'', value : (metadata.elements.x00081110) ? metadata.string(metadata.elements.x00081110.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0008,2111)' ,description : 'Derivation Description' , vr : (metadata.elements.x00082111) ?  metadata.elements.x00082111.vr :'', length : (metadata.elements.x00082111) ? metadata.elements.x00082111.length :'', value : (metadata.elements.x00082111) ? metadata.string(metadata.elements.x00082111.tag) :''},
        { tag_id : '(0008,2112)' ,description : 'Source Image Sequence' , vr : (metadata.elements.x00082112) ?  metadata.elements.x00082112.vr :'', length : (metadata.elements.x00082112) ? metadata.elements.x00082112.length :'', value : (metadata.elements.x00082112) ? metadata.string(metadata.elements.x00082112.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,1150)' ,description : 'Referenced SOP Class UID' , vr : (metadata.elements.x00081150) ?  metadata.elements.x00081150.vr :'', length : (metadata.elements.x00081150) ? metadata.elements.x00081150.length :'', value : (metadata.elements.x00081150) ? metadata.string(metadata.elements.x00081150.tag) :''},
        { tag_id : '(0008,1155)' ,description : 'Referenced SOP Instance UID' , vr : (metadata.elements.x00081155) ?  metadata.elements.x00081155.vr :'', length : (metadata.elements.x00081155) ? metadata.elements.x00081155.length :'', value : (metadata.elements.x00081155) ? metadata.string(metadata.elements.x00081155.tag) :''},
        { tag_id : '(0028,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00280000) ?  metadata.elements.x00280000.vr :'', length : (metadata.elements.x00280000) ? metadata.elements.x00280000.length :'', value : (metadata.elements.x00280000) ? metadata.string(metadata.elements.x00280000.tag) :''},
        { tag_id : '(0028,135A)' ,description : 'Spatial Locations Preserved' , vr : (metadata.elements.x0028135A) ?  metadata.elements.x0028135A.vr :'', length : (metadata.elements.x0028135A) ? metadata.elements.x0028135A.length :'', value : (metadata.elements.x0028135A) ? metadata.string(metadata.elements.x0028135A.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0008,2218)' ,description : 'Anatomic Region Sequence' , vr : (metadata.elements.x00082218) ?  metadata.elements.x00082218.vr :'', length : (metadata.elements.x00082218) ? metadata.elements.x00082218.length :'', value : (metadata.elements.x00082218) ? metadata.string(metadata.elements.x00082218.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,0100)' ,description : 'Code Value' , vr : (metadata.elements.x00080100) ?  metadata.elements.x00080100.vr :'', length : (metadata.elements.x00080100) ? metadata.elements.x00080100.length :'', value : (metadata.elements.x00080100) ? metadata.string(metadata.elements.x00080100.tag) :''},
        { tag_id : '(0008,0102)' ,description : 'Coding Scheme Designator' , vr : (metadata.elements.x00080102) ?  metadata.elements.x00080102.vr :'', length : (metadata.elements.x00080102) ? metadata.elements.x00080102.length :'', value : (metadata.elements.x00080102) ? metadata.string(metadata.elements.x00080102.tag) :''},
        { tag_id : '(0008,0104)' ,description : 'Code Meaning' , vr : (metadata.elements.x00080104) ?  metadata.elements.x00080104.vr :'', length : (metadata.elements.x00080104) ? metadata.elements.x00080104.length :'', value : (metadata.elements.x00080104) ? metadata.string(metadata.elements.x00080104.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0008,3010)' ,description : 'Irradiation Event UID' , vr : (metadata.elements.x00083010) ?  metadata.elements.x00083010.vr :'', length : (metadata.elements.x00083010) ? metadata.elements.x00083010.length :'', value : (metadata.elements.x00083010) ? metadata.string(metadata.elements.x00083010.tag) :''},
        { tag_id : '(0009,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00090000) ?  metadata.elements.x00090000.vr :'', length : (metadata.elements.x00090000) ? metadata.elements.x00090000.length :'', value : (metadata.elements.x00090000) ? metadata.string(metadata.elements.x00090000.tag) :''},
        { tag_id : '(0009,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x00090010) ?  metadata.elements.x00090010.vr :'', length : (metadata.elements.x00090010) ? metadata.elements.x00090010.length :'', value : (metadata.elements.x00090010) ? metadata.string(metadata.elements.x00090010.tag) :''},
        { tag_id : '(0009,1004)' ,description : '' , vr : (metadata.elements.x00091004) ?  metadata.elements.x00091004.vr :'', length : (metadata.elements.x00091004) ? metadata.elements.x00091004.length :'', value : (metadata.elements.x00091004) ? metadata.string(metadata.elements.x00091004.tag) :''},
        { tag_id : '(0009,1005)' ,description : '' , vr : (metadata.elements.x00091005) ?  metadata.elements.x00091005.vr :'', length : (metadata.elements.x00091005) ? metadata.elements.x00091005.length :'', value : (metadata.elements.x00091005) ? metadata.string(metadata.elements.x00091005.tag) :''},
        { tag_id : '(0009,1006)' ,description : '' , vr : (metadata.elements.x00091006) ?  metadata.elements.x00091006.vr :'', length : (metadata.elements.x00091006) ? metadata.elements.x00091006.length :'', value : (metadata.elements.x00091006) ? metadata.string(metadata.elements.x00091006.tag) :''},
        { tag_id : '(0009,1008)' ,description : '' , vr : (metadata.elements.x00091008) ?  metadata.elements.x00091008.vr :'', length : (metadata.elements.x00091008) ? metadata.elements.x00091008.length :'', value : (metadata.elements.x00091008) ? metadata.string(metadata.elements.x00091008.tag) :''},
        { tag_id : '(0009,1009)' ,description : '' , vr : (metadata.elements.x00091009) ?  metadata.elements.x00091009.vr :'', length : (metadata.elements.x00091009) ? metadata.elements.x00091009.length :'', value : (metadata.elements.x00091009) ? metadata.string(metadata.elements.x00091009.tag) :''},
        { tag_id : '(0009,100C)' ,description : '' , vr : (metadata.elements.x0009100C) ?  metadata.elements.x0009100C.vr :'', length : (metadata.elements.x0009100C) ? metadata.elements.x0009100C.length :'', value : (metadata.elements.x0009100C) ? metadata.string(metadata.elements.x0009100C.tag) :''},
        { tag_id : '(0009,1010)' ,description : '' , vr : (metadata.elements.x00091010) ?  metadata.elements.x00091010.vr :'', length : (metadata.elements.x00091010) ? metadata.elements.x00091010.length :'', value : (metadata.elements.x00091010) ? metadata.string(metadata.elements.x00091010.tag) :''},
        { tag_id : '(0009,1030)' ,description : '' , vr : (metadata.elements.x00091030) ?  metadata.elements.x00091030.vr :'', length : (metadata.elements.x00091030) ? metadata.elements.x00091030.length :'', value : (metadata.elements.x00091030) ? metadata.string(metadata.elements.x00091030.tag) :''},
        { tag_id : '(0009,1080)' ,description : '' , vr : (metadata.elements.x00091080) ?  metadata.elements.x00091080.vr :'', length : (metadata.elements.x00091080) ? metadata.elements.x00091080.length :'', value : (metadata.elements.x00091080) ? metadata.string(metadata.elements.x00091080.tag) :''},
        { tag_id : '(0009,1090)' ,description : '' , vr : (metadata.elements.x00091090) ?  metadata.elements.x00091090.vr :'', length : (metadata.elements.x00091090) ? metadata.elements.x00091090.length :'', value : (metadata.elements.x00091090) ? metadata.string(metadata.elements.x00091090.tag) :''},
        { tag_id : '(0009,1092)' ,description : '' , vr : (metadata.elements.x00091092) ?  metadata.elements.x00091092.vr :'', length : (metadata.elements.x00091092) ? metadata.elements.x00091092.length :'', value : (metadata.elements.x00091092) ? metadata.string(metadata.elements.x00091092.tag) :''},
        { tag_id : '(0009,10F0)' ,description : '' , vr : (metadata.elements.x000910F0) ?  metadata.elements.x000910F0.vr :'', length : (metadata.elements.x000910F0) ? metadata.elements.x000910F0.length :'', value : (metadata.elements.x000910F0) ? metadata.string(metadata.elements.x000910F0.tag) :''},
        { tag_id : '(0009,10F1)' ,description : '' , vr : (metadata.elements.x000910F1) ?  metadata.elements.x000910F1.vr :'', length : (metadata.elements.x000910F1) ? metadata.elements.x000910F1.length :'', value : (metadata.elements.x000910F1) ? metadata.string(metadata.elements.x000910F1.tag) :''},
        { tag_id : '(0009,10F2)' ,description : '' , vr : (metadata.elements.x000910F2) ?  metadata.elements.x000910F2.vr :'', length : (metadata.elements.x000910F2) ? metadata.elements.x000910F2.length :'', value : (metadata.elements.x000910F2) ? metadata.string(metadata.elements.x000910F2.tag) :''},
        { tag_id : '(0009,10F3)' ,description : '' , vr : (metadata.elements.x000910F3) ?  metadata.elements.x000910F3.vr :'', length : (metadata.elements.x000910F3) ? metadata.elements.x000910F3.length :'', value : (metadata.elements.x000910F3) ? metadata.string(metadata.elements.x000910F3.tag) :''},
        { tag_id : '(0010,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00100000) ?  metadata.elements.x00100000.vr :'', length : (metadata.elements.x00100000) ? metadata.elements.x00100000.length :'', value : (metadata.elements.x00100000) ? metadata.string(metadata.elements.x00100000.tag) :''},
        { tag_id : '(0010,0010)' ,description : 'Patient´s Name' , vr : (metadata.elements.x00100010) ?  metadata.elements.x00100010.vr :'', length : (metadata.elements.x00100010) ? metadata.elements.x00100010.length :'', value : (metadata.elements.x00100010) ? metadata.string(metadata.elements.x00100010.tag) :''},
        { tag_id : '(0010,0020)' ,description : 'Patient ID' , vr : (metadata.elements.x00100020) ?  metadata.elements.x00100020.vr :'', length : (metadata.elements.x00100020) ? metadata.elements.x00100020.length :'', value : (metadata.elements.x00100020) ? metadata.string(metadata.elements.x00100020.tag) :''},
        { tag_id : '(0010,0030)' ,description : 'Patient´s Birth Date' , vr : (metadata.elements.x00100030) ?  metadata.elements.x00100030.vr :'', length : (metadata.elements.x00100030) ? metadata.elements.x00100030.length :'', value : (metadata.elements.x00100030) ? metadata.string(metadata.elements.x00100030.tag) :''},
        { tag_id : '(0010,0040)' ,description : 'Patient´s Sex' , vr : (metadata.elements.x00100040) ?  metadata.elements.x00100040.vr :'', length : (metadata.elements.x00100040) ? metadata.elements.x00100040.length :'', value : (metadata.elements.x00100040) ? metadata.string(metadata.elements.x00100040.tag) :''},
        { tag_id : '(0010,1000)' ,description : 'Other Patient IDs' , vr : (metadata.elements.x00101000) ?  metadata.elements.x00101000.vr :'', length : (metadata.elements.x00101000) ? metadata.elements.x00101000.length :'', value : (metadata.elements.x00101000) ? metadata.string(metadata.elements.x00101000.tag) :''},
        { tag_id : '(0010,1010)' ,description : 'Patient´s Age' , vr : (metadata.elements.x00101010) ?  metadata.elements.x00101010.vr :'', length : (metadata.elements.x00101010) ? metadata.elements.x00101010.length :'', value : (metadata.elements.x00101010) ? metadata.string(metadata.elements.x00101010.tag) :''},
        { tag_id : '(0010,2160)' ,description : 'Ethnic Group' , vr : (metadata.elements.x00102160) ?  metadata.elements.x00102160.vr :'', length : (metadata.elements.x00102160) ? metadata.elements.x00102160.length :'', value : (metadata.elements.x00102160) ? metadata.string(metadata.elements.x00102160.tag) :''},
        { tag_id : '(0010,21B0)' ,description : 'Additional Patient History' , vr : (metadata.elements.x001021B0) ?  metadata.elements.x001021B0.vr :'', length : (metadata.elements.x001021B0) ? metadata.elements.x001021B0.length :'', value : (metadata.elements.x001021B0) ? metadata.string(metadata.elements.x001021B0.tag) :''},
        { tag_id : '(0010,4000)' ,description : 'Patient Comments' , vr : (metadata.elements.x00104000) ?  metadata.elements.x00104000.vr :'', length : (metadata.elements.x00104000) ? metadata.elements.x00104000.length :'', value : (metadata.elements.x00104000) ? metadata.string(metadata.elements.x00104000.tag) :''},
        { tag_id : '(0018,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00180000) ?  metadata.elements.x00180000.vr :'', length : (metadata.elements.x00180000) ? metadata.elements.x00180000.length :'', value : (metadata.elements.x00180000) ? metadata.string(metadata.elements.x00180000.tag) :''},
        { tag_id : '(0018,0010)' ,description : 'Contrast/Bolus Agent' , vr : (metadata.elements.x00180010) ?  metadata.elements.x00180010.vr :'', length : (metadata.elements.x00180010) ? metadata.elements.x00180010.length :'', value : (metadata.elements.x00180010) ? metadata.string(metadata.elements.x00180010.tag) :''},
        { tag_id : '(0018,0015)' ,description : 'Body Part Examined' , vr : (metadata.elements.x00180015) ?  metadata.elements.x00180015.vr :'', length : (metadata.elements.x00180015) ? metadata.elements.x00180015.length :'', value : (metadata.elements.x00180015) ? metadata.string(metadata.elements.x00180015.tag) :''},
        { tag_id : '(0018,0060)' ,description : 'KVP' , vr : (metadata.elements.x00180060) ?  metadata.elements.x00180060.vr :'', length : (metadata.elements.x00180060) ? metadata.elements.x00180060.length :'', value : (metadata.elements.x00180060) ? metadata.string(metadata.elements.x00180060.tag) :''},
        { tag_id : '(0018,1000)' ,description : 'Device Serial Number' , vr : (metadata.elements.x00181000) ?  metadata.elements.x00181000.vr :'', length : (metadata.elements.x00181000) ? metadata.elements.x00181000.length :'', value : (metadata.elements.x00181000) ? metadata.string(metadata.elements.x00181000.tag) :''},
        { tag_id : '(0018,1008)' ,description : 'Gantry ID' , vr : (metadata.elements.x00181008) ?  metadata.elements.x00181008.vr :'', length : (metadata.elements.x00181008) ? metadata.elements.x00181008.length :'', value : (metadata.elements.x00181008) ? metadata.string(metadata.elements.x00181008.tag) :''},
        { tag_id : '(0018,1020)' ,description : 'Software Version(s)' , vr : (metadata.elements.x00181020) ?  metadata.elements.x00181020.vr :'', length : (metadata.elements.x00181020) ? metadata.elements.x00181020.length :'', value : (metadata.elements.x00181020) ? metadata.string(metadata.elements.x00181020.tag) :''},
        { tag_id : '(0018,1110)' ,description : 'Distance Source to Detector' , vr : (metadata.elements.x00181110) ?  metadata.elements.x00181110.vr :'', length : (metadata.elements.x00181110) ? metadata.elements.x00181110.length :'', value : (metadata.elements.x00181110) ? metadata.string(metadata.elements.x00181110.tag) :''},
        { tag_id : '(0018,1114)' ,description : 'Estimated Radiographic Magnification Factor' , vr : (metadata.elements.x00181114) ?  metadata.elements.x00181114.vr :'', length : (metadata.elements.x00181114) ? metadata.elements.x00181114.length :'', value : (metadata.elements.x00181114) ? metadata.string(metadata.elements.x00181114.tag) :''},
        { tag_id : '(0018,1147)' ,description : 'Field of View Shape' , vr : (metadata.elements.x00181147) ?  metadata.elements.x00181147.vr :'', length : (metadata.elements.x00181147) ? metadata.elements.x00181147.length :'', value : (metadata.elements.x00181147) ? metadata.string(metadata.elements.x00181147.tag) :''},
        { tag_id : '(0018,1149)' ,description : 'Field of View Dimension(s)' , vr : (metadata.elements.x00181149) ?  metadata.elements.x00181149.vr :'', length : (metadata.elements.x00181149) ? metadata.elements.x00181149.length :'', value : (metadata.elements.x00181149) ? metadata.string(metadata.elements.x00181149.tag) :''},
        { tag_id : '(0018,1150)' ,description : 'Exposure Time' , vr : (metadata.elements.x00181150) ?  metadata.elements.x00181150.vr :'', length : (metadata.elements.x00181150) ? metadata.elements.x00181150.length :'', value : (metadata.elements.x00181150) ? metadata.string(metadata.elements.x00181150.tag) :''},
        { tag_id : '(0018,1151)' ,description : 'X-Ray Tube Current' , vr : (metadata.elements.x00181151) ?  metadata.elements.x00181151.vr :'', length : (metadata.elements.x00181151) ? metadata.elements.x00181151.length :'', value : (metadata.elements.x00181151) ? metadata.string(metadata.elements.x00181151.tag) :''},
        { tag_id : '(0018,1152)' ,description : 'Exposure' , vr : (metadata.elements.x00181152) ?  metadata.elements.x00181152.vr :'', length : (metadata.elements.x00181152) ? metadata.elements.x00181152.length :'', value : (metadata.elements.x00181152) ? metadata.string(metadata.elements.x00181152.tag) :''},
        { tag_id : '(0018,1153)' ,description : 'Exposure in ÂµAs' , vr : (metadata.elements.x00181153) ?  metadata.elements.x00181153.vr :'', length : (metadata.elements.x00181153) ? metadata.elements.x00181153.length :'', value : (metadata.elements.x00181153) ? metadata.string(metadata.elements.x00181153.tag) :''},
        { tag_id : '(0018,1164)' ,description : 'Imager Pixel Spacing' , vr : (metadata.elements.x00181164) ?  metadata.elements.x00181164.vr :'', length : (metadata.elements.x00181164) ? metadata.elements.x00181164.length :'', value : (metadata.elements.x00181164) ? metadata.string(metadata.elements.x00181164.tag) :''},
        { tag_id : '(0018,1166)' ,description : 'Grid' , vr : (metadata.elements.x00181166) ?  metadata.elements.x00181166.vr :'', length : (metadata.elements.x00181166) ? metadata.elements.x00181166.length :'', value : (metadata.elements.x00181166) ? metadata.string(metadata.elements.x00181166.tag) :''},
        { tag_id : '(0018,1191)' ,description : 'Anode Target Material' , vr : (metadata.elements.x00181191) ?  metadata.elements.x00181191.vr :'', length : (metadata.elements.x00181191) ? metadata.elements.x00181191.length :'', value : (metadata.elements.x00181191) ? metadata.string(metadata.elements.x00181191.tag) :''},
        { tag_id : '(0018,11A0)' ,description : 'Body Part Thickness' , vr : (metadata.elements.x001811A0) ?  metadata.elements.x001811A0.vr :'', length : (metadata.elements.x001811A0) ? metadata.elements.x001811A0.length :'', value : (metadata.elements.x001811A0) ? metadata.string(metadata.elements.x001811A0.tag) :''},
        { tag_id : '(0018,11A2)' ,description : 'Compression Force' , vr : (metadata.elements.x001811A2) ?  metadata.elements.x001811A2.vr :'', length : (metadata.elements.x001811A2) ? metadata.elements.x001811A2.length :'', value : (metadata.elements.x001811A2) ? metadata.string(metadata.elements.x001811A2.tag) :''},
        { tag_id : '(0018,1400)' ,description : 'Acquisition Device Processing Description' , vr : (metadata.elements.x00181400) ?  metadata.elements.x00181400.vr :'', length : (metadata.elements.x00181400) ? metadata.elements.x00181400.length :'', value : (metadata.elements.x00181400) ? metadata.string(metadata.elements.x00181400.tag) :''},
        { tag_id : '(0018,1401)' ,description : 'Acquisition Device Processing Code' , vr : (metadata.elements.x00181401) ?  metadata.elements.x00181401.vr :'', length : (metadata.elements.x00181401) ? metadata.elements.x00181401.length :'', value : (metadata.elements.x00181401) ? metadata.string(metadata.elements.x00181401.tag) :''},
        { tag_id : '(0018,1405)' ,description : 'Relative X-Ray Exposure' , vr : (metadata.elements.x00181405) ?  metadata.elements.x00181405.vr :'', length : (metadata.elements.x00181405) ? metadata.elements.x00181405.length :'', value : (metadata.elements.x00181405) ? metadata.string(metadata.elements.x00181405.tag) :''},
        { tag_id : '(0018,1508)' ,description : 'Positioner Type' , vr : (metadata.elements.x00181508) ?  metadata.elements.x00181508.vr :'', length : (metadata.elements.x00181508) ? metadata.elements.x00181508.length :'', value : (metadata.elements.x00181508) ? metadata.string(metadata.elements.x00181508.tag) :''},
        { tag_id : '(0018,1510)' ,description : 'Positioner Primary Angle' , vr : (metadata.elements.x00181510) ?  metadata.elements.x00181510.vr :'', length : (metadata.elements.x00181510) ? metadata.elements.x00181510.length :'', value : (metadata.elements.x00181510) ? metadata.string(metadata.elements.x00181510.tag) :''},
        { tag_id : '(0018,6000)' ,description : 'Sensitivity' , vr : (metadata.elements.x00186000) ?  metadata.elements.x00186000.vr :'', length : (metadata.elements.x00186000) ? metadata.elements.x00186000.length :'', value : (metadata.elements.x00186000) ? metadata.string(metadata.elements.x00186000.tag) :''},
        { tag_id : '(0018,7004)' ,description : 'Detector Type' , vr : (metadata.elements.x00187004) ?  metadata.elements.x00187004.vr :'', length : (metadata.elements.x00187004) ? metadata.elements.x00187004.length :'', value : (metadata.elements.x00187004) ? metadata.string(metadata.elements.x00187004.tag) :''},
        { tag_id : '(0018,7005)' ,description : 'Detector Configuration' , vr : (metadata.elements.x00187005) ?  metadata.elements.x00187005.vr :'', length : (metadata.elements.x00187005) ? metadata.elements.x00187005.length :'', value : (metadata.elements.x00187005) ? metadata.string(metadata.elements.x00187005.tag) :''},
        { tag_id : '(0018,700A)' ,description : 'Detector ID' , vr : (metadata.elements.x0018700A) ?  metadata.elements.x0018700A.vr :'', length : (metadata.elements.x0018700A) ? metadata.elements.x0018700A.length :'', value : (metadata.elements.x0018700A) ? metadata.string(metadata.elements.x0018700A.tag) :''},
        { tag_id : '(0018,700C)' ,description : 'Date of Last Detector Calibration' , vr : (metadata.elements.x0018700C) ?  metadata.elements.x0018700C.vr :'', length : (metadata.elements.x0018700C) ? metadata.elements.x0018700C.length :'', value : (metadata.elements.x0018700C) ? metadata.string(metadata.elements.x0018700C.tag) :''},
        { tag_id : '(0018,7050)' ,description : 'Filter Material' , vr : (metadata.elements.x00187050) ?  metadata.elements.x00187050.vr :'', length : (metadata.elements.x00187050) ? metadata.elements.x00187050.length :'', value : (metadata.elements.x00187050) ? metadata.string(metadata.elements.x00187050.tag) :''},
        { tag_id : '(0018,7060)' ,description : 'Exposure Control Mode' , vr : (metadata.elements.x00187060) ?  metadata.elements.x00187060.vr :'', length : (metadata.elements.x00187060) ? metadata.elements.x00187060.length :'', value : (metadata.elements.x00187060) ? metadata.string(metadata.elements.x00187060.tag) :''},
        { tag_id : '(0018,7062)' ,description : 'Exposure Control Mode Description' , vr : (metadata.elements.x00187062) ?  metadata.elements.x00187062.vr :'', length : (metadata.elements.x00187062) ? metadata.elements.x00187062.length :'', value : (metadata.elements.x00187062) ? metadata.string(metadata.elements.x00187062.tag) :''},
        { tag_id : '(0019,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00190000) ?  metadata.elements.x00190000.vr :'', length : (metadata.elements.x00190000) ? metadata.elements.x00190000.length :'', value : (metadata.elements.x00190000) ? metadata.string(metadata.elements.x00190000.tag) :''},
        { tag_id : '(0019,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x00190010) ?  metadata.elements.x00190010.vr :'', length : (metadata.elements.x00190010) ? metadata.elements.x00190010.length :'', value : (metadata.elements.x00190010) ? metadata.string(metadata.elements.x00190010.tag) :''},
        { tag_id : '(0019,1015)' ,description : '' , vr : (metadata.elements.x00191015) ?  metadata.elements.x00191015.vr :'', length : (metadata.elements.x00191015) ? metadata.elements.x00191015.length :'', value : (metadata.elements.x00191015) ? metadata.string(metadata.elements.x00191015.tag) :''},
        { tag_id : '(0019,1020)' ,description : '' , vr : (metadata.elements.x00191020) ?  metadata.elements.x00191020.vr :'', length : (metadata.elements.x00191020) ? metadata.elements.x00191020.length :'', value : (metadata.elements.x00191020) ? metadata.string(metadata.elements.x00191020.tag) :''},
        { tag_id : '(0019,1022)' ,description : '' , vr : (metadata.elements.x00191022) ?  metadata.elements.x00191022.vr :'', length : (metadata.elements.x00191022) ? metadata.elements.x00191022.length :'', value : (metadata.elements.x00191022) ? metadata.string(metadata.elements.x00191022.tag) :''},
        { tag_id : '(0019,1023)' ,description : '' , vr : (metadata.elements.x00191023) ?  metadata.elements.x00191023.vr :'', length : (metadata.elements.x00191023) ? metadata.elements.x00191023.length :'', value : (metadata.elements.x00191023) ? metadata.string(metadata.elements.x00191023.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0018,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00180000) ?  metadata.elements.x00180000.vr :'', length : (metadata.elements.x00180000) ? metadata.elements.x00180000.length :'', value : (metadata.elements.x00180000) ? metadata.string(metadata.elements.x00180000.tag) :''},
        { tag_id : '(0018,0060)' ,description : 'KVP' , vr : (metadata.elements.x00180060) ?  metadata.elements.x00180060.vr :'', length : (metadata.elements.x00180060) ? metadata.elements.x00180060.length :'', value : (metadata.elements.x00180060) ? metadata.string(metadata.elements.x00180060.tag) :''},
        { tag_id : '(0018,1150)' ,description : 'Exposure Time' , vr : (metadata.elements.x00181150) ?  metadata.elements.x00181150.vr :'', length : (metadata.elements.x00181150) ? metadata.elements.x00181150.length :'', value : (metadata.elements.x00181150) ? metadata.string(metadata.elements.x00181150.tag) :''},
        { tag_id : '(0018,1151)' ,description : 'X-Ray Tube Current' , vr : (metadata.elements.x00181151) ?  metadata.elements.x00181151.vr :'', length : (metadata.elements.x00181151) ? metadata.elements.x00181151.length :'', value : (metadata.elements.x00181151) ? metadata.string(metadata.elements.x00181151.tag) :''},
        { tag_id : '(0018,1152)' ,description : 'Exposure' , vr : (metadata.elements.x00181152) ?  metadata.elements.x00181152.vr :'', length : (metadata.elements.x00181152) ? metadata.elements.x00181152.length :'', value : (metadata.elements.x00181152) ? metadata.string(metadata.elements.x00181152.tag) :''},
        { tag_id : '(0018,1153)' ,description : 'Exposure in ÂµAs' , vr : (metadata.elements.x00181153) ?  metadata.elements.x00181153.vr :'', length : (metadata.elements.x00181153) ? metadata.elements.x00181153.length :'', value : (metadata.elements.x00181153) ? metadata.string(metadata.elements.x00181153.tag) :''},
        { tag_id : '(0018,1191)' ,description : 'Anode Target Material' , vr : (metadata.elements.x00181191) ?  metadata.elements.x00181191.vr :'', length : (metadata.elements.x00181191) ? metadata.elements.x00181191.length :'', value : (metadata.elements.x00181191) ? metadata.string(metadata.elements.x00181191.tag) :''},
        { tag_id : '(0018,7050)' ,description : 'Filter Material' , vr : (metadata.elements.x00187050) ?  metadata.elements.x00187050.vr :'', length : (metadata.elements.x00187050) ? metadata.elements.x00187050.length :'', value : (metadata.elements.x00187050) ? metadata.string(metadata.elements.x00187050.tag) :''},
        { tag_id : '(0040,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00400000) ?  metadata.elements.x00400000.vr :'', length : (metadata.elements.x00400000) ? metadata.elements.x00400000.length :'', value : (metadata.elements.x00400000) ? metadata.string(metadata.elements.x00400000.tag) :''},
        { tag_id : '(0040,0316)' ,description : 'Organ Dose' , vr : (metadata.elements.x00400316) ?  metadata.elements.x00400316.vr :'', length : (metadata.elements.x00400316) ? metadata.elements.x00400316.length :'', value : (metadata.elements.x00400316) ? metadata.string(metadata.elements.x00400316.tag) :''},
        { tag_id : '(0040,8302)' ,description : 'Entrance Dose in mGy' , vr : (metadata.elements.x00408302) ?  metadata.elements.x00408302.vr :'', length : (metadata.elements.x00408302) ? metadata.elements.x00408302.length :'', value : (metadata.elements.x00408302) ? metadata.string(metadata.elements.x00408302.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0019,1032)' ,description : '' , vr : (metadata.elements.x00191032) ?  metadata.elements.x00191032.vr :'', length : (metadata.elements.x00191032) ? metadata.elements.x00191032.length :'', value : (metadata.elements.x00191032) ? metadata.string(metadata.elements.x00191032.tag) :''},
        { tag_id : '(0019,1040)' ,description : '' , vr : (metadata.elements.x00191040) ?  metadata.elements.x00191040.vr :'', length : (metadata.elements.x00191040) ? metadata.elements.x00191040.length :'', value : (metadata.elements.x00191040) ? metadata.string(metadata.elements.x00191040.tag) :''},
        { tag_id : '(0019,1050)' ,description : '' , vr : (metadata.elements.x00191050) ?  metadata.elements.x00191050.vr :'', length : (metadata.elements.x00191050) ? metadata.elements.x00191050.length :'', value : (metadata.elements.x00191050) ? metadata.string(metadata.elements.x00191050.tag) :''},
        { tag_id : '(0019,1060)' ,description : '' , vr : (metadata.elements.x00191060) ?  metadata.elements.x00191060.vr :'', length : (metadata.elements.x00191060) ? metadata.elements.x00191060.length :'', value : (metadata.elements.x00191060) ? metadata.string(metadata.elements.x00191060.tag) :''},
        { tag_id : '(0019,1080)' ,description : '' , vr : (metadata.elements.x00191080) ?  metadata.elements.x00191080.vr :'', length : (metadata.elements.x00191080) ? metadata.elements.x00191080.length :'', value : (metadata.elements.x00191080) ? metadata.string(metadata.elements.x00191080.tag) :''},
        { tag_id : '(0019,1081)' ,description : '' , vr : (metadata.elements.x00191081) ?  metadata.elements.x00191081.vr :'', length : (metadata.elements.x00191081) ? metadata.elements.x00191081.length :'', value : (metadata.elements.x00191081) ? metadata.string(metadata.elements.x00191081.tag) :''},
        { tag_id : '(0019,1090)' ,description : '' , vr : (metadata.elements.x00191090) ?  metadata.elements.x00191090.vr :'', length : (metadata.elements.x00191090) ? metadata.elements.x00191090.length :'', value : (metadata.elements.x00191090) ? metadata.string(metadata.elements.x00191090.tag) :''},
        { tag_id : '(0019,1091)' ,description : '' , vr : (metadata.elements.x00191091) ?  metadata.elements.x00191091.vr :'', length : (metadata.elements.x00191091) ? metadata.elements.x00191091.length :'', value : (metadata.elements.x00191091) ? metadata.string(metadata.elements.x00191091.tag) :''},
        { tag_id : '(0020,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00200000) ?  metadata.elements.x00200000.vr :'', length : (metadata.elements.x00200000) ? metadata.elements.x00200000.length :'', value : (metadata.elements.x00200000) ? metadata.string(metadata.elements.x00200000.tag) :''},
        { tag_id : '(0020,000D)' ,description : 'Study Instance UID' , vr : (metadata.elements.x0020000D) ?  metadata.elements.x0020000D.vr :'', length : (metadata.elements.x0020000D) ? metadata.elements.x0020000D.length :'', value : (metadata.elements.x0020000D) ? metadata.string(metadata.elements.x0020000D.tag) :''},
        { tag_id : '(0020,000E)' ,description : 'Series Instance UID' , vr : (metadata.elements.x0020000e) ?  metadata.elements.x0020000e.vr :'', length : (metadata.elements.x0020000e) ? metadata.elements.x0020000e.length :'', value : (metadata.elements.x0020000e) ? metadata.string(metadata.elements.x0020000e.tag) :''},
        { tag_id : '(0020,0010)' ,description : 'Study ID' , vr : (metadata.elements.x00200010) ?  metadata.elements.x00200010.vr :'', length : (metadata.elements.x00200010) ? metadata.elements.x00200010.length :'', value : (metadata.elements.x00200010) ? metadata.string(metadata.elements.x00200010.tag) :''},
        { tag_id : '(0020,0011)' ,description : 'Series Number' , vr : (metadata.elements.x00200011) ?  metadata.elements.x00200011.vr :'', length : (metadata.elements.x00200011) ? metadata.elements.x00200011.length :'', value : (metadata.elements.x00200011) ? metadata.string(metadata.elements.x00200011.tag) :''},
        { tag_id : '(0020,0012)' ,description : 'Acquisition Number' , vr : (metadata.elements.x00200012) ?  metadata.elements.x00200012.vr :'', length : (metadata.elements.x00200012) ? metadata.elements.x00200012.length :'', value : (metadata.elements.x00200012) ? metadata.string(metadata.elements.x00200012.tag) :''},
        { tag_id : '(0020,0013)' ,description : 'Instance Number' , vr : (metadata.elements.x00200013) ?  metadata.elements.x00200013.vr :'', length : (metadata.elements.x00200013) ? metadata.elements.x00200013.length :'', value : (metadata.elements.x00200013) ? metadata.string(metadata.elements.x00200013.tag) :''},
        { tag_id : '(0020,0020)' ,description : 'Patient Orientation' , vr : (metadata.elements.x00200020) ?  metadata.elements.x00200020.vr :'', length : (metadata.elements.x00200020) ? metadata.elements.x00200020.length :'', value : (metadata.elements.x00200020) ? metadata.string(metadata.elements.x00200020.tag) :''},
        { tag_id : '(0020,0060)' ,description : 'Laterality' , vr : (metadata.elements.x00200060) ?  metadata.elements.x00200060.vr :'', length : (metadata.elements.x00200060) ? metadata.elements.x00200060.length :'', value : (metadata.elements.x00200060) ? metadata.string(metadata.elements.x00200060.tag) :''},
        { tag_id : '(0020,0062)' ,description : 'Image Laterality' , vr : (metadata.elements.x00200062) ?  metadata.elements.x00200062.vr :'', length : (metadata.elements.x00200062) ? metadata.elements.x00200062.length :'', value : (metadata.elements.x00200062) ? metadata.string(metadata.elements.x00200062.tag) :''},
        { tag_id : '(0021,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00210000) ?  metadata.elements.x00210000.vr :'', length : (metadata.elements.x00210000) ? metadata.elements.x00210000.length :'', value : (metadata.elements.x00210000) ? metadata.string(metadata.elements.x00210000.tag) :''},
        { tag_id : '(0021,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x00210010) ?  metadata.elements.x00210010.vr :'', length : (metadata.elements.x00210010) ? metadata.elements.x00210010.length :'', value : (metadata.elements.x00210010) ? metadata.string(metadata.elements.x00210010.tag) :''},
        { tag_id : '(0021,1010)' ,description : '' , vr : (metadata.elements.x00211010) ?  metadata.elements.x00211010.vr :'', length : (metadata.elements.x00211010) ? metadata.elements.x00211010.length :'', value : (metadata.elements.x00211010) ? metadata.string(metadata.elements.x00211010.tag) :''},
        { tag_id : '(0021,1030)' ,description : '' , vr : (metadata.elements.x00211030) ?  metadata.elements.x00211030.vr :'', length : (metadata.elements.x00211030) ? metadata.elements.x00211030.length :'', value : (metadata.elements.x00211030) ? metadata.string(metadata.elements.x00211030.tag) :''},
        { tag_id : '(0021,1040)' ,description : '' , vr : (metadata.elements.x00211040) ?  metadata.elements.x00211040.vr :'', length : (metadata.elements.x00211040) ? metadata.elements.x00211040.length :'', value : (metadata.elements.x00211040) ? metadata.string(metadata.elements.x00211040.tag) :''},
        { tag_id : '(0021,1050)' ,description : '' , vr : (metadata.elements.x00211050) ?  metadata.elements.x00211050.vr :'', length : (metadata.elements.x00211050) ? metadata.elements.x00211050.length :'', value : (metadata.elements.x00211050) ? metadata.string(metadata.elements.x00211050.tag) :''},
        { tag_id : '(0021,1080)' ,description : '' , vr : (metadata.elements.x00211080) ?  metadata.elements.x00211080.vr :'', length : (metadata.elements.x00211080) ? metadata.elements.x00211080.length :'', value : (metadata.elements.x00211080) ? metadata.string(metadata.elements.x00211080.tag) :''},
        { tag_id : '(0025,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00250000) ?  metadata.elements.x00250000.vr :'', length : (metadata.elements.x00250000) ? metadata.elements.x00250000.length :'', value : (metadata.elements.x00250000) ? metadata.string(metadata.elements.x00250000.tag) :''},
        { tag_id : '(0025,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x00250010) ?  metadata.elements.x00250010.vr :'', length : (metadata.elements.x00250010) ? metadata.elements.x00250010.length :'', value : (metadata.elements.x00250010) ? metadata.string(metadata.elements.x00250010.tag) :''},
        { tag_id : '(0025,1010)' ,description : '' , vr : (metadata.elements.x00251010) ?  metadata.elements.x00251010.vr :'', length : (metadata.elements.x00251010) ? metadata.elements.x00251010.length :'', value : (metadata.elements.x00251010) ? metadata.string(metadata.elements.x00251010.tag) :''},
        { tag_id : '(0025,1011)' ,description : '' , vr : (metadata.elements.x00251011) ?  metadata.elements.x00251011.vr :'', length : (metadata.elements.x00251011) ? metadata.elements.x00251011.length :'', value : (metadata.elements.x00251011) ? metadata.string(metadata.elements.x00251011.tag) :''},
        { tag_id : '(0025,1012)' ,description : '' , vr : (metadata.elements.x00251012) ?  metadata.elements.x00251012.vr :'', length : (metadata.elements.x00251012) ? metadata.elements.x00251012.length :'', value : (metadata.elements.x00251012) ? metadata.string(metadata.elements.x00251012.tag) :''},
        { tag_id : '(0028,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00280000) ?  metadata.elements.x00280000.vr :'', length : (metadata.elements.x00280000) ? metadata.elements.x00280000.length :'', value : (metadata.elements.x00280000) ? metadata.string(metadata.elements.x00280000.tag) :''},
        { tag_id : '(0028,0002)' ,description : 'Samples per Pixel' , vr : (metadata.elements.x00280002) ?  metadata.elements.x00280002.vr :'', length : (metadata.elements.x00280002) ? metadata.elements.x00280002.length :'', value : (metadata.elements.x00280002) ? metadata.string(metadata.elements.x00280002.tag) :''},
        { tag_id : '(0028,0004)' ,description : 'Photometric Interpretation' , vr : (metadata.elements.x00280004) ?  metadata.elements.x00280004.vr :'', length : (metadata.elements.x00280004) ? metadata.elements.x00280004.length :'', value : (metadata.elements.x00280004) ? metadata.string(metadata.elements.x00280004.tag) :''},
        { tag_id : '(0028,0010)' ,description : 'Rows' , vr : (metadata.elements.x00280010) ?  metadata.elements.x00280010.vr :'', length : (metadata.elements.x00280010) ? metadata.elements.x00280010.length :'', value : (metadata.elements.x00280010) ? metadata.string(metadata.elements.x00280010.tag) :''},
        { tag_id : '(0028,0011)' ,description : 'Columns' , vr : (metadata.elements.x00280011) ?  metadata.elements.x00280011.vr :'', length : (metadata.elements.x00280011) ? metadata.elements.x00280011.length :'', value : (metadata.elements.x00280011) ? metadata.string(metadata.elements.x00280011.tag) :''},
        { tag_id : '(0028,0030)' ,description : 'Pixel Spacing' , vr : (metadata.elements.x00280030) ?  metadata.elements.x00280030.vr :'', length : (metadata.elements.x00280030) ? metadata.elements.x00280030.length :'', value : (metadata.elements.x00280030) ? metadata.string(metadata.elements.x00280030.tag) :''},
        { tag_id : '(0028,0100)' ,description : 'Bits Allocated' , vr : (metadata.elements.x00280100) ?  metadata.elements.x00280100.vr :'', length : (metadata.elements.x00280100) ? metadata.elements.x00280100.length :'', value : (metadata.elements.x00280100) ? metadata.string(metadata.elements.x00280100.tag) :''},
        { tag_id : '(0028,0101)' ,description : 'Bits Stored' , vr : (metadata.elements.x00280101) ?  metadata.elements.x00280101.vr :'', length : (metadata.elements.x00280101) ? metadata.elements.x00280101.length :'', value : (metadata.elements.x00280101) ? metadata.string(metadata.elements.x00280101.tag) :''},
        { tag_id : '(0028,0102)' ,description : 'High Bit' , vr : (metadata.elements.x00280102) ?  metadata.elements.x00280102.vr :'', length : (metadata.elements.x00280102) ? metadata.elements.x00280102.length :'', value : (metadata.elements.x00280102) ? metadata.string(metadata.elements.x00280102.tag) :''},
        { tag_id : '(0028,0103)' ,description : 'Pixel Representation' , vr : (metadata.elements.x00280103) ?  metadata.elements.x00280103.vr :'', length : (metadata.elements.x00280103) ? metadata.elements.x00280103.length :'', value : (metadata.elements.x00280103) ? metadata.string(metadata.elements.x00280103.tag) :''},
        { tag_id : '(0028,0301)' ,description : 'Burned In Annotation' , vr : (metadata.elements.x00280301) ?  metadata.elements.x00280301.vr :'', length : (metadata.elements.x00280301) ? metadata.elements.x00280301.length :'', value : (metadata.elements.x00280301) ? metadata.string(metadata.elements.x00280301.tag) :''},
        { tag_id : '(0028,1040)' ,description : 'Pixel Intensity Relationship' , vr : (metadata.elements.x00281040) ?  metadata.elements.x00281040.vr :'', length : (metadata.elements.x00281040) ? metadata.elements.x00281040.length :'', value : (metadata.elements.x00281040) ? metadata.string(metadata.elements.x00281040.tag) :''},
        { tag_id : '(0028,1041)' ,description : 'Pixel Intensity Relationship Sign' , vr : (metadata.elements.x00281041) ?  metadata.elements.x00281041.vr :'', length : (metadata.elements.x00281041) ? metadata.elements.x00281041.length :'', value : (metadata.elements.x00281041) ? metadata.string(metadata.elements.x00281041.tag) :''},
        { tag_id : '(0028,1050)' ,description : 'Window Center' , vr : (metadata.elements.x00281050) ?  metadata.elements.x00281050.vr :'', length : (metadata.elements.x00281050) ? metadata.elements.x00281050.length :'', value : (metadata.elements.x00281050) ? metadata.string(metadata.elements.x00281050.tag) :''},
        { tag_id : '(0028,1051)' ,description : 'Window Width' , vr : (metadata.elements.x00281051) ?  metadata.elements.x00281051.vr :'', length : (metadata.elements.x00281051) ? metadata.elements.x00281051.length :'', value : (metadata.elements.x00281051) ? metadata.string(metadata.elements.x00281051.tag) :''},
        { tag_id : '(0028,1052)' ,description : 'Rescale Intercept' , vr : (metadata.elements.x00281052) ?  metadata.elements.x00281052.vr :'', length : (metadata.elements.x00281052) ? metadata.elements.x00281052.length :'', value : (metadata.elements.x00281052) ? metadata.string(metadata.elements.x00281052.tag) :''},
        { tag_id : '(0028,1053)' ,description : 'Rescale Slope' , vr : (metadata.elements.x00281053) ?  metadata.elements.x00281053.vr :'', length : (metadata.elements.x00281053) ? metadata.elements.x00281053.length :'', value : (metadata.elements.x00281053) ? metadata.string(metadata.elements.x00281053.tag) :''},
        { tag_id : '(0028,1054)' ,description : 'Rescale Type' , vr : (metadata.elements.x00281054) ?  metadata.elements.x00281054.vr :'', length : (metadata.elements.x00281054) ? metadata.elements.x00281054.length :'', value : (metadata.elements.x00281054) ? metadata.string(metadata.elements.x00281054.tag) :''},
        { tag_id : '(0028,1056)' ,description : 'VOI LUT Function' , vr : (metadata.elements.x00281056) ?  metadata.elements.x00281056.vr :'', length : (metadata.elements.x00281056) ? metadata.elements.x00281056.length :'', value : (metadata.elements.x00281056) ? metadata.string(metadata.elements.x00281056.tag) :''},
        { tag_id : '(0028,1300)' ,description : 'Breast Implant Present' , vr : (metadata.elements.x00281300) ?  metadata.elements.x00281300.vr :'', length : (metadata.elements.x00281300) ? metadata.elements.x00281300.length :'', value : (metadata.elements.x00281300) ? metadata.string(metadata.elements.x00281300.tag) :''},
        { tag_id : '(0028,1350)' ,description : 'Partial View' , vr : (metadata.elements.x00281350) ?  metadata.elements.x00281350.vr :'', length : (metadata.elements.x00281350) ? metadata.elements.x00281350.length :'', value : (metadata.elements.x00281350) ? metadata.string(metadata.elements.x00281350.tag) :''},
        { tag_id : '(0028,2110)' ,description : 'Lossy Image Compression' , vr : (metadata.elements.x00282110) ?  metadata.elements.x00282110.vr :'', length : (metadata.elements.x00282110) ? metadata.elements.x00282110.length :'', value : (metadata.elements.x00282110) ? metadata.string(metadata.elements.x00282110.tag) :''},
        { tag_id : '(0028,2112)' ,description : 'Lossy Image Compression Ratio' , vr : (metadata.elements.x00282112) ?  metadata.elements.x00282112.vr :'', length : (metadata.elements.x00282112) ? metadata.elements.x00282112.length :'', value : (metadata.elements.x00282112) ? metadata.string(metadata.elements.x00282112.tag) :''},
        { tag_id : '(0029,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00290000) ?  metadata.elements.x00290000.vr :'', length : (metadata.elements.x00290000) ? metadata.elements.x00290000.length :'', value : (metadata.elements.x00290000) ? metadata.string(metadata.elements.x00290000.tag) :''},
        { tag_id : '(0029,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x00290010) ?  metadata.elements.x00290010.vr :'', length : (metadata.elements.x00290010) ? metadata.elements.x00290010.length :'', value : (metadata.elements.x00290010) ? metadata.string(metadata.elements.x00290010.tag) :''},
        { tag_id : '(0029,1020)' ,description : '' , vr : (metadata.elements.x00291020) ?  metadata.elements.x00291020.vr :'', length : (metadata.elements.x00291020) ? metadata.elements.x00291020.length :'', value : (metadata.elements.x00291020) ? metadata.string(metadata.elements.x00291020.tag) :''},
        { tag_id : '(0029,1030)' ,description : '' , vr : (metadata.elements.x00291030) ?  metadata.elements.x00291030.vr :'', length : (metadata.elements.x00291030) ? metadata.elements.x00291030.length :'', value : (metadata.elements.x00291030) ? metadata.string(metadata.elements.x00291030.tag) :''},
        { tag_id : '(0029,1034)' ,description : '' , vr : (metadata.elements.x00291034) ?  metadata.elements.x00291034.vr :'', length : (metadata.elements.x00291034) ? metadata.elements.x00291034.length :'', value : (metadata.elements.x00291034) ? metadata.string(metadata.elements.x00291034.tag) :''},
        { tag_id : '(0029,1044)' ,description : '' , vr : (metadata.elements.x00291044) ?  metadata.elements.x00291044.vr :'', length : (metadata.elements.x00291044) ? metadata.elements.x00291044.length :'', value : (metadata.elements.x00291044) ? metadata.string(metadata.elements.x00291044.tag) :''},
        { tag_id : '(0029,1050)' ,description : '' , vr : (metadata.elements.x00291050) ?  metadata.elements.x00291050.vr :'', length : (metadata.elements.x00291050) ? metadata.elements.x00291050.length :'', value : (metadata.elements.x00291050) ? metadata.string(metadata.elements.x00291050.tag) :''},
        { tag_id : '(0029,1060)' ,description : '' , vr : (metadata.elements.x00291060) ?  metadata.elements.x00291060.vr :'', length : (metadata.elements.x00291060) ? metadata.elements.x00291060.length :'', value : (metadata.elements.x00291060) ? metadata.string(metadata.elements.x00291060.tag) :''},
        { tag_id : '(0029,1061)' ,description : '' , vr : (metadata.elements.x00291061) ?  metadata.elements.x00291061.vr :'', length : (metadata.elements.x00291061) ? metadata.elements.x00291061.length :'', value : (metadata.elements.x00291061) ? metadata.string(metadata.elements.x00291061.tag) :''},
        { tag_id : '(0032,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00320000) ?  metadata.elements.x00320000.vr :'', length : (metadata.elements.x00320000) ? metadata.elements.x00320000.length :'', value : (metadata.elements.x00320000) ? metadata.string(metadata.elements.x00320000.tag) :''},
        { tag_id : '(0032,1032)' ,description : 'Requesting Physician' , vr : (metadata.elements.x00321032) ?  metadata.elements.x00321032.vr :'', length : (metadata.elements.x00321032) ? metadata.elements.x00321032.length :'', value : (metadata.elements.x00321032) ? metadata.string(metadata.elements.x00321032.tag) :''},
        { tag_id : '(0032,1033)' ,description : 'Requesting Service' , vr : (metadata.elements.x00321033) ?  metadata.elements.x00321033.vr :'', length : (metadata.elements.x00321033) ? metadata.elements.x00321033.length :'', value : (metadata.elements.x00321033) ? metadata.string(metadata.elements.x00321033.tag) :''},
        { tag_id : '(0040,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00400000) ?  metadata.elements.x00400000.vr :'', length : (metadata.elements.x00400000) ? metadata.elements.x00400000.length :'', value : (metadata.elements.x00400000) ? metadata.string(metadata.elements.x00400000.tag) :''},
        { tag_id : '(0040,0253)' ,description : 'Performed Procedure Step ID' , vr : (metadata.elements.x00400253) ?  metadata.elements.x00400253.vr :'', length : (metadata.elements.x00400253) ? metadata.elements.x00400253.length :'', value : (metadata.elements.x00400253) ? metadata.string(metadata.elements.x00400253.tag) :''},
        { tag_id : '(0040,0254)' ,description : 'Performed Procedure Step Description' , vr : (metadata.elements.x00400254) ?  metadata.elements.x00400254.vr :'', length : (metadata.elements.x00400254) ? metadata.elements.x00400254.length :'', value : (metadata.elements.x00400254) ? metadata.string(metadata.elements.x00400254.tag) :''},
        { tag_id : '(0040,0275)' ,description : 'Request Attributes Sequence' , vr : (metadata.elements.x00400275) ?  metadata.elements.x00400275.vr :'', length : (metadata.elements.x00400275) ? metadata.elements.x00400275.length :'', value : (metadata.elements.x00400275) ? metadata.string(metadata.elements.x00400275.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0040,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00400000) ?  metadata.elements.x00400000.vr :'', length : (metadata.elements.x00400000) ? metadata.elements.x00400000.length :'', value : (metadata.elements.x00400000) ? metadata.string(metadata.elements.x00400000.tag) :''},
        { tag_id : '(0040,0007)' ,description : 'Scheduled Procedure Step Description' , vr : (metadata.elements.x00400007) ?  metadata.elements.x00400007.vr :'', length : (metadata.elements.x00400007) ? metadata.elements.x00400007.length :'', value : (metadata.elements.x00400007) ? metadata.string(metadata.elements.x00400007.tag) :''},
        { tag_id : '(0040,0008)' ,description : 'Scheduled Protocol Code Sequence' , vr : (metadata.elements.x00400008) ?  metadata.elements.x00400008.vr :'', length : (metadata.elements.x00400008) ? metadata.elements.x00400008.length :'', value : (metadata.elements.x00400008) ? metadata.string(metadata.elements.x00400008.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,0100)' ,description : 'Code Value' , vr : (metadata.elements.x00080100) ?  metadata.elements.x00080100.vr :'', length : (metadata.elements.x00080100) ? metadata.elements.x00080100.length :'', value : (metadata.elements.x00080100) ? metadata.string(metadata.elements.x00080100.tag) :''},
        { tag_id : '(0008,0102)' ,description : 'Coding Scheme Designator' , vr : (metadata.elements.x00080102) ?  metadata.elements.x00080102.vr :'', length : (metadata.elements.x00080102) ? metadata.elements.x00080102.length :'', value : (metadata.elements.x00080102) ? metadata.string(metadata.elements.x00080102.tag) :''},
        { tag_id : '(0008,0103)' ,description : 'Coding Scheme Version' , vr : (metadata.elements.x00080103) ?  metadata.elements.x00080103.vr :'', length : (metadata.elements.x00080103) ? metadata.elements.x00080103.length :'', value : (metadata.elements.x00080103) ? metadata.string(metadata.elements.x00080103.tag) :''},
        { tag_id : '(0008,0104)' ,description : 'Code Meaning' , vr : (metadata.elements.x00080104) ?  metadata.elements.x00080104.vr :'', length : (metadata.elements.x00080104) ? metadata.elements.x00080104.length :'', value : (metadata.elements.x00080104) ? metadata.string(metadata.elements.x00080104.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0040,0009)' ,description : 'Scheduled Procedure Step ID' , vr : (metadata.elements.x00400009) ?  metadata.elements.x00400009.vr :'', length : (metadata.elements.x00400009) ? metadata.elements.x00400009.length :'', value : (metadata.elements.x00400009) ? metadata.string(metadata.elements.x00400009.tag) :''},
        { tag_id : '(0040,1001)' ,description : 'Requested Procedure ID' , vr : (metadata.elements.x00401001) ?  metadata.elements.x00401001.vr :'', length : (metadata.elements.x00401001) ? metadata.elements.x00401001.length :'', value : (metadata.elements.x00401001) ? metadata.string(metadata.elements.x00401001.tag) :''},
        { tag_id : '(0040,100A)' ,description : 'Reason for Requested Procedure Code Sequence' , vr : (metadata.elements.x0040100A) ?  metadata.elements.x0040100A.vr :'', length : (metadata.elements.x0040100A) ? metadata.elements.x0040100A.length :'', value : (metadata.elements.x0040100A) ? metadata.string(metadata.elements.x0040100A.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,0100)' ,description : 'Code Value' , vr : (metadata.elements.x00080100) ?  metadata.elements.x00080100.vr :'', length : (metadata.elements.x00080100) ? metadata.elements.x00080100.length :'', value : (metadata.elements.x00080100) ? metadata.string(metadata.elements.x00080100.tag) :''},
        { tag_id : '(0008,0102)' ,description : 'Coding Scheme Designator' , vr : (metadata.elements.x00080102) ?  metadata.elements.x00080102.vr :'', length : (metadata.elements.x00080102) ? metadata.elements.x00080102.length :'', value : (metadata.elements.x00080102) ? metadata.string(metadata.elements.x00080102.tag) :''},
        { tag_id : '(0008,0103)' ,description : 'Coding Scheme Version' , vr : (metadata.elements.x00080103) ?  metadata.elements.x00080103.vr :'', length : (metadata.elements.x00080103) ? metadata.elements.x00080103.length :'', value : (metadata.elements.x00080103) ? metadata.string(metadata.elements.x00080103.tag) :''},
        { tag_id : '(0008,0104)' ,description : 'Code Meaning' , vr : (metadata.elements.x00080104) ?  metadata.elements.x00080104.vr :'', length : (metadata.elements.x00080104) ? metadata.elements.x00080104.length :'', value : (metadata.elements.x00080104) ? metadata.string(metadata.elements.x00080104.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0040,0302)' ,description : 'Entrance Dose' , vr : (metadata.elements.x00400302) ?  metadata.elements.x00400302.vr :'', length : (metadata.elements.x00400302) ? metadata.elements.x00400302.length :'', value : (metadata.elements.x00400302) ? metadata.string(metadata.elements.x00400302.tag) :''},
        { tag_id : '(0040,0316)' ,description : 'Organ Dose' , vr : (metadata.elements.x00400316) ?  metadata.elements.x00400316.vr :'', length : (metadata.elements.x00400316) ? metadata.elements.x00400316.length :'', value : (metadata.elements.x00400316) ? metadata.string(metadata.elements.x00400316.tag) :''},
        { tag_id : '(0040,0318)' ,description : 'Organ Exposed' , vr : (metadata.elements.x00400318) ?  metadata.elements.x00400318.vr :'', length : (metadata.elements.x00400318) ? metadata.elements.x00400318.length :'', value : (metadata.elements.x00400318) ? metadata.string(metadata.elements.x00400318.tag) :''},
        { tag_id : '(0040,0555)' ,description : 'Acquisition Context Sequence' , vr : (metadata.elements.x00400555) ?  metadata.elements.x00400555.vr :'', length : (metadata.elements.x00400555) ? metadata.elements.x00400555.length :'', value : (metadata.elements.x00400555) ? metadata.string(metadata.elements.x00400555.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0040,1012)' ,description : 'Reason For Performed Procedure Code Sequence' , vr : (metadata.elements.x00401012) ?  metadata.elements.x00401012.vr :'', length : (metadata.elements.x00401012) ? metadata.elements.x00401012.length :'', value : (metadata.elements.x00401012) ? metadata.string(metadata.elements.x00401012.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,0100)' ,description : 'Code Value' , vr : (metadata.elements.x00080100) ?  metadata.elements.x00080100.vr :'', length : (metadata.elements.x00080100) ? metadata.elements.x00080100.length :'', value : (metadata.elements.x00080100) ? metadata.string(metadata.elements.x00080100.tag) :''},
        { tag_id : '(0008,0102)' ,description : 'Coding Scheme Designator' , vr : (metadata.elements.x00080102) ?  metadata.elements.x00080102.vr :'', length : (metadata.elements.x00080102) ? metadata.elements.x00080102.length :'', value : (metadata.elements.x00080102) ? metadata.string(metadata.elements.x00080102.tag) :''},
        { tag_id : '(0008,0103)' ,description : 'Coding Scheme Version' , vr : (metadata.elements.x00080103) ?  metadata.elements.x00080103.vr :'', length : (metadata.elements.x00080103) ? metadata.elements.x00080103.length :'', value : (metadata.elements.x00080103) ? metadata.string(metadata.elements.x00080103.tag) :''},
        { tag_id : '(0008,0104)' ,description : 'Code Meaning' , vr : (metadata.elements.x00080104) ?  metadata.elements.x00080104.vr :'', length : (metadata.elements.x00080104) ? metadata.elements.x00080104.length :'', value : (metadata.elements.x00080104) ? metadata.string(metadata.elements.x00080104.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0040,8302)' ,description : 'Entrance Dose in mGy' , vr : (metadata.elements.x00408302) ?  metadata.elements.x00408302.vr :'', length : (metadata.elements.x00408302) ? metadata.elements.x00408302.length :'', value : (metadata.elements.x00408302) ? metadata.string(metadata.elements.x00408302.tag) :''},
        { tag_id : '(0054,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00540000) ?  metadata.elements.x00540000.vr :'', length : (metadata.elements.x00540000) ? metadata.elements.x00540000.length :'', value : (metadata.elements.x00540000) ? metadata.string(metadata.elements.x00540000.tag) :''},
        { tag_id : '(0054,0220)' ,description : 'Code Sequence' , vr : (metadata.elements.x00540220) ?  metadata.elements.x00540220.vr :'', length : (metadata.elements.x00540220) ? metadata.elements.x00540220.length :'', value : (metadata.elements.x00540220) ? metadata.string(metadata.elements.x00540220.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(0008,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00080000) ?  metadata.elements.x00080000.vr :'', length : (metadata.elements.x00080000) ? metadata.elements.x00080000.length :'', value : (metadata.elements.x00080000) ? metadata.string(metadata.elements.x00080000.tag) :''},
        { tag_id : '(0008,0100)' ,description : 'Code Value' , vr : (metadata.elements.x00080100) ?  metadata.elements.x00080100.vr :'', length : (metadata.elements.x00080100) ? metadata.elements.x00080100.length :'', value : (metadata.elements.x00080100) ? metadata.string(metadata.elements.x00080100.tag) :''},
        { tag_id : '(0008,0102)' ,description : 'Coding Scheme Designator' , vr : (metadata.elements.x00080102) ?  metadata.elements.x00080102.vr :'', length : (metadata.elements.x00080102) ? metadata.elements.x00080102.length :'', value : (metadata.elements.x00080102) ? metadata.string(metadata.elements.x00080102.tag) :''},
        { tag_id : '(0008,0104)' ,description : 'Code Meaning' , vr : (metadata.elements.x00080104) ?  metadata.elements.x00080104.vr :'', length : (metadata.elements.x00080104) ? metadata.elements.x00080104.length :'', value : (metadata.elements.x00080104) ? metadata.string(metadata.elements.x00080104.tag) :''},
        { tag_id : '(0054,0000)' ,description : 'Group Length' , vr : (metadata.elements.x00540000) ?  metadata.elements.x00540000.vr :'', length : (metadata.elements.x00540000) ? metadata.elements.x00540000.length :'', value : (metadata.elements.x00540000) ? metadata.string(metadata.elements.x00540000.tag) :''},
        { tag_id : '(0054,0222)' ,description : 'View Modifier Code Sequence' , vr : (metadata.elements.x00540222) ?  metadata.elements.x00540222.vr :'', length : (metadata.elements.x00540222) ? metadata.elements.x00540222.length :'', value : (metadata.elements.x00540222) ? metadata.string(metadata.elements.x00540222.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(FFFE,E00D)' ,description : 'Item Delimitation Item' , vr : (metadata.elements.xFFFEE00D) ?  metadata.elements.xFFFEE00D.vr :'', length : (metadata.elements.xFFFEE00D) ? metadata.elements.xFFFEE00D.length :'', value : (metadata.elements.xFFFEE00D) ? metadata.string(metadata.elements.xFFFEE00D.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(2010,0000)' ,description : 'Group Length' , vr : (metadata.elements.x20100000) ?  metadata.elements.x20100000.vr :'', length : (metadata.elements.x20100000) ? metadata.elements.x20100000.length :'', value : (metadata.elements.x20100000) ? metadata.string(metadata.elements.x20100000.tag) :''},
        { tag_id : '(2010,0010)' ,description : 'Image Display Format' , vr : (metadata.elements.x20100010) ?  metadata.elements.x20100010.vr :'', length : (metadata.elements.x20100010) ? metadata.elements.x20100010.length :'', value : (metadata.elements.x20100010) ? metadata.string(metadata.elements.x20100010.tag) :''},
        { tag_id : '(2010,0030)' ,description : 'Annotation Display Format ID' , vr : (metadata.elements.x20100030) ?  metadata.elements.x20100030.vr :'', length : (metadata.elements.x20100030) ? metadata.elements.x20100030.length :'', value : (metadata.elements.x20100030) ? metadata.string(metadata.elements.x20100030.tag) :''},
        { tag_id : '(2010,0040)' ,description : 'Film Orientation' , vr : (metadata.elements.x20100040) ?  metadata.elements.x20100040.vr :'', length : (metadata.elements.x20100040) ? metadata.elements.x20100040.length :'', value : (metadata.elements.x20100040) ? metadata.string(metadata.elements.x20100040.tag) :''},
        { tag_id : '(2010,0100)' ,description : 'Border Density' , vr : (metadata.elements.x20100100) ?  metadata.elements.x20100100.vr :'', length : (metadata.elements.x20100100) ? metadata.elements.x20100100.length :'', value : (metadata.elements.x20100100) ? metadata.string(metadata.elements.x20100100.tag) :''},
        { tag_id : '(2010,0140)' ,description : 'Trim' , vr : (metadata.elements.x20100140) ?  metadata.elements.x20100140.vr :'', length : (metadata.elements.x20100140) ? metadata.elements.x20100140.length :'', value : (metadata.elements.x20100140) ? metadata.string(metadata.elements.x20100140.tag) :''},
        { tag_id : '(2011,0000)' ,description : 'Group Length' , vr : (metadata.elements.x20110000) ?  metadata.elements.x20110000.vr :'', length : (metadata.elements.x20110000) ? metadata.elements.x20110000.length :'', value : (metadata.elements.x20110000) ? metadata.string(metadata.elements.x20110000.tag) :''},
        { tag_id : '(2011,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x20110010) ?  metadata.elements.x20110010.vr :'', length : (metadata.elements.x20110010) ? metadata.elements.x20110010.length :'', value : (metadata.elements.x20110010) ? metadata.string(metadata.elements.x20110010.tag) :''},
        { tag_id : '(2011,1011)' ,description : '' , vr : (metadata.elements.x20111011) ?  metadata.elements.x20111011.vr :'', length : (metadata.elements.x20111011) ? metadata.elements.x20111011.length :'', value : (metadata.elements.x20111011) ? metadata.string(metadata.elements.x20111011.tag) :''},
        { tag_id : '(2020,0000)' ,description : 'Group Length' , vr : (metadata.elements.x20200000) ?  metadata.elements.x20200000.vr :'', length : (metadata.elements.x20200000) ? metadata.elements.x20200000.length :'', value : (metadata.elements.x20200000) ? metadata.string(metadata.elements.x20200000.tag) :''},
        { tag_id : '(2020,0010)' ,description : 'Image Box Position' , vr : (metadata.elements.x20200010) ?  metadata.elements.x20200010.vr :'', length : (metadata.elements.x20200010) ? metadata.elements.x20200010.length :'', value : (metadata.elements.x20200010) ? metadata.string(metadata.elements.x20200010.tag) :''},
        { tag_id : '(2050,0000)' ,description : 'Group Length' , vr : (metadata.elements.x20500000) ?  metadata.elements.x20500000.vr :'', length : (metadata.elements.x20500000) ? metadata.elements.x20500000.length :'', value : (metadata.elements.x20500000) ? metadata.string(metadata.elements.x20500000.tag) :''},
        { tag_id : '(2050,0020)' ,description : 'Presentation LUT Shape' , vr : (metadata.elements.x20500020) ?  metadata.elements.x20500020.vr :'', length : (metadata.elements.x20500020) ? metadata.elements.x20500020.length :'', value : (metadata.elements.x20500020) ? metadata.string(metadata.elements.x20500020.tag) :''},
        { tag_id : '(50F1,0000)' ,description : 'Group Length' , vr : (metadata.elements.x50F10000) ?  metadata.elements.x50F10000.vr :'', length : (metadata.elements.x50F10000) ? metadata.elements.x50F10000.length :'', value : (metadata.elements.x50F10000) ? metadata.string(metadata.elements.x50F10000.tag) :''},
        { tag_id : '(50F1,0010)' ,description : 'Private Creator' , vr : (metadata.elements.x50F10010) ?  metadata.elements.x50F10010.vr :'', length : (metadata.elements.x50F10010) ? metadata.elements.x50F10010.length :'', value : (metadata.elements.x50F10010) ? metadata.string(metadata.elements.x50F10010.tag) :''},
        { tag_id : '(50F1,100B)' ,description : '' , vr : (metadata.elements.x50F1100B) ?  metadata.elements.x50F1100B.vr :'', length : (metadata.elements.x50F1100B) ? metadata.elements.x50F1100B.length :'', value : (metadata.elements.x50F1100B) ? metadata.string(metadata.elements.x50F1100B.tag) :''},
        { tag_id : '(50F1,1010)' ,description : '' , vr : (metadata.elements.x50F11010) ?  metadata.elements.x50F11010.vr :'', length : (metadata.elements.x50F11010) ? metadata.elements.x50F11010.length :'', value : (metadata.elements.x50F11010) ? metadata.string(metadata.elements.x50F11010.tag) :''},
        { tag_id : '(50F1,1020)' ,description : '' , vr : (metadata.elements.x50F11020) ?  metadata.elements.x50F11020.vr :'', length : (metadata.elements.x50F11020) ? metadata.elements.x50F11020.length :'', value : (metadata.elements.x50F11020) ? metadata.string(metadata.elements.x50F11020.tag) :''},
        { tag_id : '(7FE0,0010)' ,description : 'Pixel Data' , vr : (metadata.elements.x7FE00010) ?  metadata.elements.x7FE00010.vr :'', length : (metadata.elements.x7FE00010) ? metadata.elements.x7FE00010.length :'', value : (metadata.elements.x7FE00010) ? metadata.string(metadata.elements.x7FE00010.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(FFFE,E000)' ,description : 'Item' , vr : (metadata.elements.xFFFEE000) ?  metadata.elements.xFFFEE000.vr :'', length : (metadata.elements.xFFFEE000) ? metadata.elements.xFFFEE000.length :'', value : (metadata.elements.xFFFEE000) ? metadata.string(metadata.elements.xFFFEE000.tag) :''},
        { tag_id : '(FFFE,E0DD)' ,description : 'Sequence Delimitation Item' , vr : (metadata.elements.xFFFEE0DD) ?  metadata.elements.xFFFEE0DD.vr :'', length : (metadata.elements.xFFFEE0DD) ? metadata.elements.xFFFEE0DD.length :'', value : (metadata.elements.xFFFEE0DD) ? metadata.string(metadata.elements.xFFFEE0DD.tag) :''},
        { tag_id : '(0018,5101)' ,description : 'View Position' , vr : (metadata.elements.x00185101) ?  metadata.elements.x00185101.vr :'', length : (metadata.elements.x00185101) ? metadata.elements.x00185101.length :'', value : (metadata.elements.x00185101) ? metadata.string(metadata.elements.x00185101.tag) :''},
    ]
    return TAGS
}
import { WindowIcon } from "@heroicons/react/24/outline";
export const DisplayToolbarButton = ({ iconStyle, setNumViewports }) => {
  const windows = [
    { quantity: 2 },
    { quantity: 3 },
    { quantity: 4 },
    { quantity: 5 },
    { quantity: 6 },
    { quantity: 8 },
  ];
  return (
    <div className="btn-group d-none d-lg-block">
      <button
        className="btn btn-dark dropdown-toggle d-flex flex-column align-items-center py-2 h-100 "
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ fontSize: "11px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={iconStyle}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
          />
        </svg>
        <div className="pt-1">Disposición</div>
      </button>
      <ul
        className="dropdown-menu dropdown-menu-dark w-auto text-center position-fixed"
        style={{ fontSize: "11px" }}
      >
        <li className="dropdown-item" onClick={() => setNumViewports(1)}>
          <WindowIcon style={iconStyle} /> 1 Ventana
        </li>
        {windows.map((element) => {
          return (
            <li
              key={element.quantity}
              className="dropdown-item"
              onClick={() => setNumViewports(element.quantity)}
            >
              <WindowIcon style={iconStyle} /> {element.quantity} Ventanas
            </li>
          );
        })}
      </ul>
    </div>
  );
};

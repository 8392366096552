import { Viewport } from "./Viewport";

export const ViewportGrid = ({
  numViewports,
  renderingEngine,
  onViewportClicked,
  activeViewport,
  isDescriptionActive,
  invertColors,
  currentSeries,
  imageList,
  activeMpr,
  descriptions,
  setDescriptions,
  study
}) => {
  const rows = numViewports > 3 ? 2 : 1;
  const cols = Math.ceil(numViewports / rows);

  return (
    <div
      className="viewport-grid"
      style={{
        gridTemplateRows: `repeat(${rows}, auto)`,
        gridTemplateColumns: `repeat(${cols}, auto)`,
      }}
    >
      {Array.from({ length: numViewports }, (_, index) => {
        const serie = currentSeries[index];

        return (
          <Viewport
            key={index}
            index={index}
            renderingEngine={renderingEngine}
            onClick={onViewportClicked}
            isActive={index === activeViewport}
            isDescriptionActive={isDescriptionActive}
            invertColors={invertColors}
            currentStack={imageList[serie]}
            activeMpr={activeMpr}
            descriptions={descriptions}
            setDescriptions={setDescriptions}
            study={study}
          />
        );
      })}
    </div>
  );
};

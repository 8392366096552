import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';


import App  from '../views/App'
import { NotFound } from '../views/NotFound';

export const AppRouter = () =>{
    return (
        <Router>
          <Routes>
            <Route path='*' exact={true} element={<NotFound/>} />
            <Route  path='/study/:soap' element={<App/>} />
          </Routes>
      </Router>
    );
  }
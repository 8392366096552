import { TagIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { getTags } from "../../core/tagsDescriptions";

export const TagsModal = ({
  iconStyle,
  activeViewport,
  isActiveMPR,
  renderingEngine,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [descriptions, setDescriptions] = useState([]);

  const openModal = () => {
    setIsModalOpen(true);
    if (renderingEngine) {
      getImageTags();
    }
  };

  const closeAndResetModal = () => {
    setIsModalOpen(false);
  };

  const getImageTags = async () => {
    const viewport = renderingEngine.getViewport(`viewport-${activeViewport}`);
    const image = viewport.getCornerstoneImage();
    const DicomTags = await getTags(image.data);
    setDescriptions(DicomTags);
    // console.log({ viewport, image, DicomTags });
  };

  return (
    <div className=" d-none d-lg-block selectable">
      <button
        className={`btn btn-dark h-100 rounded-0 d-flex flex-column align-items-center py-2 ${isActiveMPR && "disabled" }`}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        onClick={openModal}
      >
        <TagIcon style={iconStyle} />
        <div className="fw-light text-white pt-1" style={{ fontSize: "11px" }}>
          Tags
        </div>
      </button>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModal2Label"
        aria-hidden={!isModalOpen}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModal2Label">
                DICOM Tags
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={closeAndResetModal}
              >
              </button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Tag ID</th>
                    <th scope="col">VR</th>
                    <th scope="col">Length</th>
                    <th scope="col">Description</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {descriptions.map((description, i) => (
                    <tr key={i}>
                      <th scope="row">{description.tag_id}</th>
                      <td>{description.vr}</td>
                      <td>{description.length}</td>
                      <td>{description.description}</td>
                      <td>{description.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeAndResetModal}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { Enums, volumeLoader, cache } from "@cornerstonejs/core";
import { VolumeViewport } from "./VolumeViewport";
import { useEffect, useState } from "react";
import {
  AngleTool,
  CrosshairsTool,
  EllipticalROITool,
  LengthTool,
  PanTool,
  PlanarRotateTool,
  ProbeTool,
  RectangleROITool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  ToolGroupManager,
  WindowLevelTool,
  ZoomTool,
  // MIPJumpToClickTool
} from "@cornerstonejs/tools";

const viewportColors = {
  "MPR-AXIAL": "rgb(200, 0, 0)",
  "MPR-SAGITTAL": "rgb(200, 200, 0)",
  "MPR-CORONAL": "rgb(0, 200, 0)",
};
const viewportReferenceLineControllable = [
  "MPR-CORONAL",
  "MPR-SAGITTAL",
  "MPR-AXIAL",
];
const viewportReferenceLineDraggableRotatable = [
  "MPR-CORONAL",
  "MPR-SAGITTAL",
  "MPR-AXIAL",
];
const viewportReferenceLineSlabThicknessControlsOn = [
  "MPR-CORONAL",
  "MPR-SAGITTAL",
  "MPR-AXIAL",
];

const getReferenceLineColor = (viewportId) => {
  return viewportColors[viewportId];
};

const getReferenceLineControllable = (viewportId) => {
  const index = viewportReferenceLineControllable.indexOf(viewportId);
  return index !== -1;
};

const getReferenceLineDraggableRotatable = (viewportId) => {
  const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
  return index !== -1;
};

const getReferenceLineSlabThicknessControlsOn = (viewportId) => {
  const index =
    viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);

  return index !== -1;
};

export const MprViewportGrid = ({
  renderingEngine,
  stack,
  serie,
  renderingEngineId,
  invertColors,
  setActiveMprViewport,
  activeMprViewport,
  toolGroupMPR,
  activeTool,
  intesity,
  setLeftClick,
  setActiveCrosshairs,
}) => {
  const [isVolumeLoaded, setIsVolumeLoaded] = useState(false);

  const [oneViewportId, setOneViewportId] = useState(undefined);

  const volumeId = `MPR_VOLUME_${serie}`;

  useEffect(() => {
    async function initVolume() {
      const volumeInCache = await cache.getVolume(volumeId);
      const volume =
        volumeInCache !== undefined
          ? volumeInCache
          : await volumeLoader.createAndCacheVolume(volumeId, {
              imageIds: [...stack],
            });
      volume.load();

      setIsVolumeLoaded(true);
    }

    initVolume();
  }, [stack, volumeId]);

  useEffect(() => {
    if (isVolumeLoaded) {
      initTools();
    }
  }, [isVolumeLoaded]);

  useEffect(() => {
    renderingEngine.resize(true, false);
  }, [renderingEngine, oneViewportId]);

  const initTools = () => {
    const isMobile = window.matchMedia("(any-pointer:coarse)").matches;

    toolGroupMPR = ToolGroupManager.getToolGroup("MPR_TOOL_GROUP");

    if (!toolGroupMPR) {
      toolGroupMPR = ToolGroupManager.createToolGroup("MPR_TOOL_GROUP");
    }

    toolGroupMPR.addViewport("MPR-CORONAL", renderingEngineId);
    toolGroupMPR.addViewport("MPR-SAGITTAL", renderingEngineId);
    toolGroupMPR.addViewport("MPR-AXIAL", renderingEngineId);
    
    toolGroupMPR.addTool(CrosshairsTool.toolName, {
      getReferenceLineColor,
      getReferenceLineControllable,
      getReferenceLineDraggableRotatable,
      getReferenceLineSlabThicknessControlsOn,
      mobile: {
        enabled: isMobile,
        opacity: 0.8,
        handleRadius: 9,
      },
    });
    toolGroupMPR.addTool(StackScrollMouseWheelTool.toolName);
    toolGroupMPR.addTool(LengthTool.toolName);
    toolGroupMPR.addTool(ProbeTool.toolName);
    toolGroupMPR.addTool(RectangleROITool.toolName);
    toolGroupMPR.addTool(StackScrollMouseWheelTool.toolName);
    toolGroupMPR.addTool(ZoomTool.toolName);
    toolGroupMPR.addTool(AngleTool.toolName);
    toolGroupMPR.addTool(PanTool.toolName);
    toolGroupMPR.addTool(EllipticalROITool.toolName);
    toolGroupMPR.addTool(PlanarRotateTool.toolName);
    toolGroupMPR.addTool(WindowLevelTool.toolName);
    toolGroupMPR.addTool(StackScrollTool.toolName);
  

    setLeftClick("Líneas MPR");
    setActiveCrosshairs(true);
    activeTool({ left: CrosshairsTool.toolName });
    toolGroupMPR.setToolActive(StackScrollMouseWheelTool.toolName);
  };

  const handleClick = (e, viewportId) => {
    if (e.detail > 1) {
      setOneViewportId((prev) => (prev ? undefined : viewportId));
    }
    setActiveMprViewport(viewportId);
  };

  return (
    <div className={oneViewportId ? `grid-full` : `grid`}>
      <div
        className={
          oneViewportId && oneViewportId !== "MPR-CORONAL"
            ? `d-none`
            : "first-column"
        }
      >
        <VolumeViewport
          orientation={Enums.OrientationAxis.CORONAL}
          renderingEngine={renderingEngine}
          volumeId={volumeId}
          viewportId="MPR-CORONAL"
          renderingEngineId={renderingEngineId}
          invertColors={invertColors}
          setActiveMprViewport={setActiveMprViewport}
          isVolumeLoaded={isVolumeLoaded}
          isActive={activeMprViewport === "MPR-CORONAL"}
          intesity={intesity}
          handleClick={handleClick}
        />
      </div>
      <div
        className={
          oneViewportId && oneViewportId !== "MPR-SAGITTAL" ? "d-none" : ""
        }
      >
        <VolumeViewport
          orientation={Enums.OrientationAxis.SAGITTAL}
          renderingEngine={renderingEngine}
          volumeId={volumeId}
          viewportId="MPR-SAGITTAL"
          renderingEngineId={renderingEngineId}
          invertColors={invertColors}
          setActiveMprViewport={setActiveMprViewport}
          isActive={activeMprViewport === "MPR-SAGITTAL"}
          isVolumeLoaded={isVolumeLoaded}
          intesity={intesity}
          handleClick={handleClick}
        />
      </div>
      <div
        className={
          oneViewportId && oneViewportId !== "MPR-AXIAL" ? "d-none" : ""
        }
      >
        <VolumeViewport
          orientation={Enums.OrientationAxis.AXIAL}
          renderingEngine={renderingEngine}
          volumeId={volumeId}
          viewportId="MPR-AXIAL"
          renderingEngineId={renderingEngineId}
          invertColors={invertColors}
          setActiveMprViewport={setActiveMprViewport}
          isActive={activeMprViewport === "MPR-AXIAL"}
          isVolumeLoaded={isVolumeLoaded}
          intesity={intesity}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getDescription } from "../core/helper";

import {
  RenderingEngine,
  utilities as csUtils,
  volumeLoader,
  setVolumesForViewports,
  getRenderingEngine,
  eventTarget,
  EVENTS,
  Enums,
  cache,
} from "@cornerstonejs/core";

import * as cornerstone from "@cornerstonejs/core";

import { getimages } from "../core/https_services";
import {
  handleimages,
  positionMg,
  getServer,
  decodeData,
  getPosition,
} from "../core/helper";
import { initDemo } from "../utils/helpers";
import {
  convertStackToVolumeViewport,
  convertVolumeToStackViewport,
} from "../utils/helpers/convertViewport";
import {
  addTool,
  PanTool,
  StackScrollMouseWheelTool,
  ZoomTool,
  AngleTool,
  ToolGroupManager,
  EllipticalROITool,
  LengthTool,
  ProbeTool,
  RectangleROITool,
  PlanarRotateTool,
  WindowLevelTool,
  Enums as csToolsEnums,
  utilities as csToolsUtilities,
  MagnifyTool,
  annotation,
  ReferenceLinesTool,
  CrosshairsTool,
  StackScrollTool,
} from "@cornerstonejs/tools";

import { Sidebar } from "./components/Sidebar";
import { ToolBar } from "./components/ToolBar";
import { ViewportGrid } from "./components/ViewportGrid";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { MprViewportGrid } from "./components/MprViewportGrid";
import { toWindowLevel } from "@cornerstonejs/core/dist/esm/utilities/windowLevel";

const { MouseBindings } = csToolsEnums;

const toolsWithAnnotations = [
  LengthTool,
  AngleTool,
  ProbeTool,
  RectangleROITool,
  EllipticalROITool,
];

function App() {
  const { soap } = useParams();

  const [series, setSeries] = useState([]);
  const [study, setStudy] = useState("");
  const [isLoadingOn, setIsLoadingOn] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [currentSeries, setCurrentSeries] = useState({});
  const [previousSeries, setPreviousSeries] = useState({});
  const [framesPerSecond, setFramesPerSecond] = useState(1);
  const [isCinePlaying, setIsCinePlaying] = useState({});
  const [invertColors, setInvertColors] = useState(false);
  const [isDescriptionActive, setisDescriptionActive] = useState(true);
  const [numViewports, setNumViewports] = useState(1);
  const [previousViewports, setPreviousViewports] = useState(1);
  const [activeViewport, setActiveViewport] = useState(0);
  const [activeMpr, setActiveMpr] = useState({});
  const [activeCrosshairs, setActiveCrosshairs] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [mgViewports, setMgViewports] = useState([]);
  const [mgImages, setMgImages] = useState(0);
  const [isActiveMPR, setIsActiveMPR] = useState(false);
  const [activeMprViewport, setActiveMprViewport] = useState("MPR-CORONAL");
  const [leftClick, setLeftClick] = useState("Pan");
  const [rightClick, setRightClick] = useState("Zoom");
  const toolGroup = useRef(null);
  const renderingEngine = useRef(null);
  const toolGroupMPR = useRef(null);
  const toolGroupId = "myToolGroup";
  const renderingEngineId = "myEngine";

  const [intesity, setIntensity] = useState("");

  useEffect(() => {
    initUtils();
  }, []);

  useEffect(() => {
    const handleResize = () => renderingEngine.current.resize(true, false);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(numViewports)
    if (!renderingEngine.current) {
      return;
    }
    if (numViewports > 1 && study === "US") {
      handleUsGrid();
    }
    renderingEngine.current.resize(true, false);
  }, [numViewports]);

  const handleUsGrid = async () => {
    console.log("heyyyyyyyyyy use grids")
    let serie = currentSeries[0];
    const imagesOfSerie = imageList[serie];
    const imagesToDisplay = Math.min(8, imagesOfSerie.length)

    Array.from({ length: imagesToDisplay }, async (_, index) => {
      const viewport = renderingEngine.current.getViewport(`viewport-${index}`);
      setCurrentSeries((prev) => ({ ...prev, [index]: serie }));
      renderSeries(serie, { viewportIndex: index });
      let newImageIdIndex = index % imagesOfSerie.length;
      await viewport.setImageIdIndex(index);
    });
  };

  useEffect(() => {
    if (
      mgImages !== 0 &&
      mgViewports.length >= 1 &&
      mgImages >= mgViewports.length
    ) {
      handleMg();
    }
  }, [mgViewports]);

  const getSerie = (object, value) => {
    for (let serie in object) {
      if (object.hasOwnProperty(serie)) {
        if (object[serie][0] === value) {
          return serie;
        }
      }
    }
    return null; // Retorna null si no se encuentra ninguna coincidencia
  };

  const handleMg = async () => {
    setNumViewports(4);
    for (const view of mgViewports) {
      let position = view.csImage.data.string("x00185101");
      let laterality = view.csImage.data.string("x00200062");
      let orientation = view.csImage.data.string("x00200020").split("\\")[0];
      var compare = `${laterality}${orientation}`;
      var to_rigth = "RP";
      var to_left = "LA";

      let on_position = await positionMg({ position, laterality });

      const viewport = renderingEngine.current.getViewport(
        `viewport-${on_position}`
      );

      if (viewport) {
        toolGroup.current.addViewport(viewport.id, renderingEngineId);
        await viewport.setStack([view.csImage.imageId]);

        const image = await viewport.getCornerstoneImage();
        const c = await viewport.getCanvas();
        let { height, width } = c;
        let screenRatio = width / 2 / 2 - width / 2 / 2 / 3;
        let pan = [
          on_position === 1 || on_position === 3 ? -screenRatio : screenRatio,
          0,
        ];
        if (compare === to_rigth || compare === to_left) console.log("noting");
        else viewport.flip("H");
        await viewport.setPan(pan);

        const serie = getSerie(imageList, view.csImage.imageId);
        setCurrentSeries((prev) => ({ ...prev, [on_position]: serie }));
        viewport.render();
      }
    }
  };

  const initUtils = async () => {
    await initDemo();

    renderingEngine.current = new RenderingEngine(renderingEngineId);

    addTool(LengthTool);
    addTool(ProbeTool);
    addTool(AngleTool);
    addTool(RectangleROITool);
    addTool(StackScrollMouseWheelTool);
    addTool(ZoomTool);
    addTool(PanTool);
    addTool(EllipticalROITool);
    addTool(PlanarRotateTool);
    addTool(WindowLevelTool);
    addTool(MagnifyTool);
    addTool(ReferenceLinesTool);
    addTool(CrosshairsTool);
    addTool(StackScrollTool);

    toolGroup.current = ToolGroupManager.createToolGroup(toolGroupId);
    toolGroupMPR.current = ToolGroupManager.createToolGroup("MPR_TOOL_GROUP");

    toolGroup.current.addTool(LengthTool.toolName);
    toolGroup.current.addTool(ProbeTool.toolName);
    toolGroup.current.addTool(RectangleROITool.toolName);
    toolGroup.current.addTool(StackScrollMouseWheelTool.toolName);
    toolGroup.current.addTool(ZoomTool.toolName);
    toolGroup.current.addTool(AngleTool.toolName);
    toolGroup.current.addTool(PanTool.toolName);
    toolGroup.current.addTool(EllipticalROITool.toolName);
    toolGroup.current.addTool(PlanarRotateTool.toolName);
    toolGroup.current.addTool(WindowLevelTool.toolName);
    toolGroup.current.addTool(MagnifyTool.toolName);
    toolGroup.current.addTool(StackScrollMouseWheelTool.toolName);
    toolGroup.current.addTool(ReferenceLinesTool.toolName);
    toolGroup.current.addTool(StackScrollTool.toolName);

    toolGroup.current.setToolActive(PanTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });

    toolGroup.current.setToolActive(ZoomTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Secondary, // Right Click
        },
        { numTouchPoints: 2 },
      ],
    });

    toolGroup.current.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Auxiliary,
        },
      ],
    });
    toolGroup.current.setToolActive(StackScrollTool.toolName, {
      bindings: [{ numTouchPoints: 3 }],
    });
    toolGroup.current.setToolActive(ZoomTool.toolName, {
      bindings: [{ numTouchPoints: 2 }],
    });

    toolGroup.current.setToolActive(StackScrollMouseWheelTool.toolName, {
      loop: true,
    });

    if (soap.includes("sicem")) {
      console.log("from SICEM");
      const dataImages = await getServer(soap);
      downloadAndView(dataImages);
    } else if (soap.includes("external_env")) {
      const dataImages = await decodeData(soap);
      downloadAndView(dataImages);
    } else {
      fetchData(soap);
    }
  };

  const fetchData = async (soap) => {
    const dataImages = await getimages(soap);
    if (dataImages) {
      downloadAndView(dataImages);
    }
  };

  const downloadAndView = async (images) => {
    const { imageList, series, type_study } = await handleimages(images);

    setSeries(series);
    setImageList(imageList);
    setIsLoadingOn(false);
    console.log(series.length)
    console.log(type_study === "MG" ? (series.length < 4 ? 2 : 4) : 1)
    setNumViewports(type_study === "MG" ? (series.length < 4 ? 2 : 4) : 1);
    if (type_study === "MG") setMgImages(series.length);

    setStudy(type_study);
    if (type_study !== "MG") {
      setTimeout(() => {
        renderSeries(series[0], { defaultStack: imageList[series[0]] });
      }, 1000);
    }

    if(type_study === "US") {
      setTimeout(()=>{
        setNumViewports(8)
      }, 1000)
     
    }
   
  };

  const renderSeries = async (series, options = {}) => {
    const viewportIndex = options.viewportIndex ?? activeViewport;
    const is_mpr = activeMpr.hasOwnProperty(viewportIndex);
    const viewportId = `viewport-${viewportIndex}`;
    const viewport = renderingEngine.current.getViewport(viewportId);

    if (!is_mpr) {
      if (viewport) {
        toolGroup.current.addViewport(viewport.id, renderingEngineId);
        const stack = options.defaultStack || imageList[series];
        await viewport.setStack(stack);
        viewport.render();
      }
    } else {
      setActiveMpr((prev) => {
        const { [activeViewport]: removedViewport, ...rest } = prev;
        return rest;
      });
      const stack = imageList[series];
      await convertVolumeToStackViewport(
        renderingEngine.current,
        viewport,
        toolGroup.current,
        `viewport-${activeViewport}`,
        stack
      );
    }
    setCurrentSeries((prev) => ({ ...prev, [viewportIndex]: series }));
  };

  const onThumbnailClick = ({ serie }) => {
    if (!isActiveMPR) stopClip();
    setTimeout(() => renderSeries(serie), 300);
  };

  const activeTool = ({ right, left }) => {
    disabledTools();
    pasiveTools();
    let toolGroupForRespectiveViewport = isActiveMPR
      ? toolGroupMPR.current
      : toolGroup.current;

    toolGroupForRespectiveViewport.setToolActive(left || leftClick, {
      bindings: [{ mouseButton: MouseBindings.Primary }],
    });

    toolGroupForRespectiveViewport.setToolActive(right || rightClick, {
      bindings: [
        {
          mouseButton: MouseBindings.Secondary,
        },
      ],
    });
    toolGroupForRespectiveViewport.setToolActive(StackScrollTool.toolName, {
      bindings: [{ numTouchPoints: 3 }],
    });
    toolGroup.current.setToolActive(ZoomTool.toolName, {
      bindings: [{ numTouchPoints: 2 }],
    });
    toolGroup.current.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Auxiliary,
        },
      ],
    });
  };

  const pasiveTools = () => {
    let toolGroupForRespectiveViewport = isActiveMPR
      ? toolGroupMPR.current
      : toolGroup.current;

    toolGroupForRespectiveViewport.setToolPassive(ProbeTool.toolName);
    toolGroupForRespectiveViewport.setToolPassive(LengthTool.toolName);
    toolGroupForRespectiveViewport.setToolPassive(RectangleROITool.toolName);
    toolGroupForRespectiveViewport.setToolPassive(AngleTool.toolName);
    toolGroupForRespectiveViewport.setToolPassive(EllipticalROITool.toolName);
    // toolGroupForRespectiveViewport.setToolPassive(ZoomTool.toolName);
    // toolGroupForRespectiveViewport.setToolPassive(PanTool.toolName);
    // toolGroupForRespectiveViewport.setToolPassive(PlanarRotateTool.toolName);
    // toolGroupForRespectiveViewport.setToolPassive(WindowLevelTool.toolName);
    // toolGroupForRespectiveViewport.setToolPassive(MagnifyTool.toolName);
  };

  const disabledTools = (tools) => {
    let toolGroupForRespectiveViewport = isActiveMPR
      ? toolGroupMPR.current
      : toolGroup.current;

    toolGroupForRespectiveViewport.setToolDisabled(ProbeTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(LengthTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(RectangleROITool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(AngleTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(EllipticalROITool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(ZoomTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(PanTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(PlanarRotateTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(WindowLevelTool.toolName);
    toolGroupForRespectiveViewport.setToolDisabled(MagnifyTool.toolName);
  };

  const disableCrosshairs = () => {
    let toolGroupForRespectiveViewport = isActiveMPR
      ? toolGroupMPR.current
      : toolGroup.current;

    toolGroupForRespectiveViewport.setToolDisabled(CrosshairsTool.toolName);
  };

  const getViewport = () => {
    let respectiveViewport = isActiveMPR
      ? activeMprViewport
      : `viewport-${activeViewport}`;
    const viewport = renderingEngine.current.getViewport(respectiveViewport);
    return viewport;
  };

  const flip = async (FlipDirection) => {
    let viewport = getViewport();
    if (FlipDirection === "H") {
      const { flipHorizontal } = viewport.getCamera();
      viewport.setCamera({ flipHorizontal: !flipHorizontal });
    } else {
      const { flipVertical } = viewport.getCamera();
      viewport.setCamera({ flipVertical: !flipVertical });
    }
    viewport.render();
  };

  const playClip = () => {
    let viewport = getViewport();
    csToolsUtilities.cine.playClip(viewport.element, {
      framesPerSecond,
    });
    setIsCinePlaying((prev) => ({ ...prev, [activeViewport]: true }));
  };

  const stopClip = () => {
    let viewport = getViewport();
    csToolsUtilities.cine.stopClip(viewport.element);
    setIsCinePlaying((prev) => ({ ...prev, [activeViewport]: false }));
  };

  const resetViewport = async () => {
    let viewport = getViewport();
    const annotations = toolsWithAnnotations.flatMap(
      (tool) =>
        annotation.state.getAnnotations(tool.toolName, viewport.element) || []
    );

    const viewportAnnotations = annotations.filter(
      ({ metadata }) =>
        metadata.referencedImageId === viewport.getCurrentImageId()
    );

    viewportAnnotations.forEach(({ annotationUID }) => {
      annotation.state.removeAnnotation(annotationUID);
    });

    viewport.resetCamera();
    viewport.resetProperties();
    viewport.render();
  };

  const handleCancelMPR = () => {
    setIsActiveMPR(false);
    if (numViewports > 1) {
      backToPreviousGrid();
    } else {
      const series = currentSeries[activeViewport];
      setNumViewports(1);
      setActiveViewport(0);
      setTimeout(() => {
        renderSeries(series, { viewportIndex: 0 });
      }, 1000);
    }
  };

  const convertToMpr = async (type) => {
    const viewport = renderingEngine.current.getViewport(
      `viewport-${activeViewport}`
    );
    console.log(viewport);
    console.log(renderingEngine.current);
    if (viewport.type === "stack") {
      const series = currentSeries[activeViewport];
      const stack = [...imageList[series]];

      setActiveMpr((prev) => ({ ...prev, [activeViewport]: "mpr" }));
      convertStackToVolumeViewport(
        renderingEngine.current,
        viewport,
        toolGroup.current,
        `viewport-${activeViewport}`,
        type,
        stack,
        series
      );
    } else {
      viewport.setOrientation(Enums.OrientationAxis[type]);
      viewport.setRendered();
    }
  };

  const onViewportClicked = (e, index) => {
    if (e.detail > 1) {
      if (numViewports > 1) {
        setPreviousViewportInfo();
        const serie = currentSeries[index];

        setNumViewports(1);
        renderSeries(serie, { viewportIndex: 0 });
      } else {
        setNumViewports(previousViewports);
        backToPreviousGrid();
      }
    } else {
      const viewportId = `viewport-${index}`;
      toolGroup.current.setToolConfiguration(ReferenceLinesTool.toolName, {
        sourceViewportId: viewportId,
      });
      setActiveViewport(index);
    }
  };
  const setPreviousViewportInfo = () => {
    setPreviousSeries(currentSeries);
    setPreviousViewports(numViewports);
    setActiveViewport(0);
  };

  const backToPreviousGrid = () => {
    Object.keys(previousSeries).forEach((index) => {
      setTimeout(() => {
        renderSeries(previousSeries[index], { viewportIndex: index });
      }, 100);
    });
  };

  const activeSeries = currentSeries[activeViewport];
  const activeStack = imageList[activeSeries];

  return (
    <div className="appContainer">
      <div className={`bg-dark toolNavbarContainer ${isLoadingOn && "d-none"}`}>
        <ToolBar
          activeTool={activeTool}
          flip={flip}
          playClip={playClip}
          stopClip={stopClip}
          isCinePlaying={isCinePlaying}
          isStackLengthMoreThan100={
            activeStack ? activeStack.length > 100 : false
          }
          isStackLengthMoreThan5={activeStack ? activeStack.length > 5 : false}
          invertColors={invertColors}
          setInvertColors={setInvertColors}
          setFramesPerSecond={setFramesPerSecond}
          framesPerSecond={framesPerSecond}
          setisDescriptionActive={setisDescriptionActive}
          isDescriptionActive={isDescriptionActive}
          setNumViewports={setNumViewports}
          numViewports={numViewports}
          renderingEngine={renderingEngine.current}
          activeViewport={activeViewport}
          convertToMpr={convertToMpr}
          resetViewport={resetViewport}
          toolGroup={toolGroup.current}
          setDescriptions={setDescriptions}
          isActiveMPR={isActiveMPR}
          setIsActiveMPR={setIsActiveMPR}
          handleCancelMPR={handleCancelMPR}
          activeMprViewport={activeMprViewport}
          setIntensity={setIntensity}
          setLeftClick={setLeftClick}
          setRightClick={setRightClick}
          zsgftvbv
          setActiveCrosshairs={setActiveCrosshairs}
          activeCrosshairs={activeCrosshairs}
          disableCrosshairs={disableCrosshairs}
          setPreviousViewportInfo={setPreviousViewportInfo}
        />
      </div>

      <div className="flex-grow-1 position-relative ">
        <div className="position-absolute top-0 start-0 bottom-0 end-0">
          {isLoadingOn ? (
            <LoadingSpinner />
          ) : (
            <div className="d-flex flex-column flex-lg-row h-100">
              <Sidebar
                series={series}
                imageList={imageList}
                study={study}
                engine={renderingEngine.current}
                onThumbnailClick={onThumbnailClick}
                setMgViewports={setMgViewports}
              />
              <div
                className="bg-black flex-grow-1 h-100"
                onContextMenu={(e) => e.preventDefault()}
              >
                {isActiveMPR ? (
                  <MprViewportGrid
                    key={currentSeries[activeViewport]}
                    renderingEngine={renderingEngine.current}
                    stack={activeStack}
                    serie={activeSeries}
                    sourceViewport={activeViewport}
                    renderingEngineId={renderingEngineId}
                    invertColors={invertColors}
                    setActiveMprViewport={setActiveMprViewport}
                    activeMprViewport={activeMprViewport}
                    toolGroupMPR={toolGroupMPR.current}
                    activeTool={activeTool}
                    intesity={intesity}
                    setLeftClick={setLeftClick}
                    setActiveCrosshairs={setActiveCrosshairs}
                    activeCrosshairs={setActiveCrosshairs}
                  />
                ) : (
                  <ViewportGrid
                    className="touchNone"
                    numViewports={numViewports}
                    renderingEngine={renderingEngine.current}
                    onViewportClicked={onViewportClicked}
                    activeViewport={activeViewport}
                    isDescriptionActive={isDescriptionActive}
                    invertColors={invertColors}
                    imageList={imageList}
                    currentSeries={currentSeries}
                    activeMpr={activeMpr}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    study={study}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;

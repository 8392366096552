const ToolsRadioButtons = ({ setClick, groupName }) => {
  return (
    <div className="d-flex justify-content-around">
      <div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Pan"
            name={groupName}
            id={`${groupName}-PanTool`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-PanTool`}>
            Mover
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Zoom"
            name={groupName}
            id={`${groupName}-ZoomTool`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-ZoomTool`}>
            Zoom
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Magnify"
            name={groupName}
            id={`${groupName}-Magnify`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-Magnify`}>
            Magnificar
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="PlanarRotate"
            name={groupName}
            id={`${groupName}-PlanarRotate`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-PlanarRotate`}>
            Rotar
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="WindowLevel"
            name={groupName}
            id={`${groupName}-contrast`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-contrast`}>
            Brillo/Contraste
          </label>
        </div>
      </div>
      <div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Length"
            name={groupName}
            id={`${groupName}-Length`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-Length`}>
            Medición
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Angle"
            name={groupName}
            id={`${groupName}-angle`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-angle`}>
            Ángulos
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="Probe"
            name={groupName}
            id={`${groupName}-roipf`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-roipf`}>
            Roi PF
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="RectangleROI"
            name={groupName}
            id={`${groupName}-roirect`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-roirect`}>
            Roi Rect
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="EllipticalROI"
            name={groupName}
            id={`${groupName}-roieliptical`}
            onChange={(e) => setClick(e.target.value)}
          />
          <label className="form-check-label" htmlFor={`${groupName}-roieliptical`}>
            Roi Elíptica
          </label>
        </div>
      </div>
    </div>
  );
};

export default ToolsRadioButtons;

export const ProgressBar = ({progress}) => {
  return (
    <div
      className="progress"
      role="progressbar"
      aria-label="Example with label"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{height: '11px'}}
    >
      <div className="progress-bar" style={{width: `${progress}%`, backgroundColor: '#4033F2'}}>
        {progress}%
      </div>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './view/App';
// import AppCopy from './App_copy';
// import Mpr from './Mpr';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { AppRouter } from './router/Approuter';
import Mpr from './Mpr';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppRouter/>);
// root.render(<Mpr/>);
// root.render(
//   <React.StrictMode>
//     <App/>
//     {/* <AppCopy/> */}
//     {/* <Mpr/> */}
//   </React.StrictMode>
// );
